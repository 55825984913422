import axios, { AxiosError } from "axios";
import { ENDPOINTS } from "@/api/endpoints";
import { forceLogout, getAccessToken, getIdToken } from "@/api/services/graph";
import { ErrorResponse, ProcessedAxiosError } from "@/api//utils/errors";

const API_TIMEOUT = 5000;

// ############## Axios Instance Connected to backend API ##############

export const backendAxiosInstance = axios.create({
  baseURL: ENDPOINTS.backendServices.base_url,
  timeout: API_TIMEOUT,
});

backendAxiosInstance.interceptors.request.use(
  async (config) => {
    const token = await getIdToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.info(`Logging user out due to ${error}`);
    forceLogout();
  }
);

backendAxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError<ErrorResponse>) => {
    if (error instanceof AxiosError) {
      throw new ProcessedAxiosError(error);
    } else {
      console.error("Unknown error returned after HTTP request made.");
    }
  }
);

// ############## Axios Instance Connected to MS Graph API ##############

export const graphAxiosInstance = axios.create({
  baseURL: ENDPOINTS.graphMe,
  timeout: API_TIMEOUT,
});

graphAxiosInstance.interceptors.request.use(
  async (config) => {
    const token = await getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

graphAxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError<ErrorResponse>) => {
    if (error instanceof AxiosError) {
      throw new ProcessedAxiosError(error);
    } else {
      console.error("Unknown error returned after HTTP request made.");
    }
  }
);
