import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

import { SUPPORTED_PLATFORMS } from "@/features/openSearch/config";
import {
  useSearchProfiles,
  useProfileSearchHistory,
  useProfileSearchResults,
} from "@/features/openSearch/hooks";

const SelectPlatforms = ({
  initialValue,
  handleChange,
}: {
  initialValue: string;
  handleChange: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <Select onValueChange={handleChange} value={initialValue}>
      <SelectTrigger className="w-1/4 min-w-fit grow">
        <SelectValue placeholder="Select a platform" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {SUPPORTED_PLATFORMS.map((platform) => {
            return (
              <SelectItem value={platform} key={platform}>
                {platform}
              </SelectItem>
            );
          })}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

/**
 * Form to search for an identity in an open search case.
 *
 * When the form is submitted, it will trigger the useSearchProfiles hook
 * to create a new search result.
 *
 * If the searchId param is present, it will display the search results
 * associated with that searchId. The search results will be used to
 * populate the form inputs.
 *
 * If the searchId param is not present, it will display the search history
 * associated with the caseId param and use the most recent search metadata
 * to populate the form inputs.
 *
 * @returns A form with a select input for the platform, an input for the
 * search entry, and a submit button.
 */
export const SearchForm = () => {
  const { id: caseId, searchId } = useParams() as {
    id: string;
    searchId: string;
  };
  const navigate = useNavigate();

  const { searchResults } = useProfileSearchResults(caseId, searchId);
  const { searchProfiles, searchId: newSearchId } = useSearchProfiles(caseId);
  const { searchHistory } = useProfileSearchHistory(caseId);

  const [selectedPlatform, setPlatform] = useState("");
  const [searchEntry, setSearchEntry] = useState("");

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedPlatform && searchEntry) {
      searchProfiles({
        platform: selectedPlatform,
        search_entry: searchEntry,
      });
    }
  };

  useEffect(() => {
    if (searchResults) {
      setPlatform(searchResults.platform);
      setSearchEntry(searchResults.search_entry);
    } else if (searchHistory) {
      /** Get form inputs from search history if results are not yet available */
      const searchMetadata = searchHistory?.find(
        (result) => result.search_id === searchId
      );
      searchMetadata && setPlatform(searchMetadata.platform);
      searchMetadata && setSearchEntry(searchMetadata.search_entry);
    }
  }, [searchResults, searchHistory, searchId]);

  useEffect(() => {
    if (newSearchId) {
      navigate(`/cases/${caseId}/open-search/${newSearchId}`);
    }
    // eslint-disable-next-line
  }, [newSearchId]);

  return (
    <form
      className="flex justify-center w-full py-2 sm:pb-0 flex-wrap space-y-2 lg:space-x-3 lg:space-y-0 lg:flex-nowrap"
      onSubmit={submit}
    >
      <SelectPlatforms
        handleChange={setPlatform}
        initialValue={selectedPlatform}
      />
      <Input
        placeholder="Search for an identity..."
        value={searchEntry}
        onChange={(e) => setSearchEntry(e.target.value)}
      ></Input>
      <Button type="submit" className="w-full lg:w-fit">
        Search
      </Button>
    </form>
  );
};
