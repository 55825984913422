import { useState } from "react";
import { Outlet, useParams } from "react-router-dom";

import { cn } from "@/lib/utils/tailwind";
import { Button } from "@/components/ui/button";
import { History } from "lucide-react";

import { GenericLoadingPage } from "@/pages/LoadingPage";

import { SearchForm } from "@/features/openSearch/SearchForm";
import { SearchHistory } from "@/features/openSearch/SearchHistory";
import { SearchResults } from "@/features/openSearch/SearchResults";
import {
  useProfileSearchHistory,
  useProfileSearchResults,
} from "@/features/openSearch/hooks";
import { ErrorPage } from "@/pages/ErrorPage";
import { isNotFoundError } from "@/api/utils/errors";
import { SearchPageLogo, SearchPageMain } from "@/layout/SearchLayout";
import OpenSearchLogo from "@/assets/open-search-logo.svg";

export const OpenSearchLandingPage = () => {
  return (
    <div className="w-full h-3/5 lg:h-1/2 content-end">
      <SearchPageLogo
        logo={<img src={OpenSearchLogo} alt="OpenSearch logo" />}
        appTitle="OpenSearch"
        appCaption="Search smarter, discover faster."
      />
      <SearchForm />
    </div>
  );
};

export const OpenSearchResultsPage = () => {
  return (
    <>
      <SearchForm />
      <SearchResults />
    </>
  );
};

export const OpenSearchIndex = () => {
  const { id: caseId, searchId } = useParams() as {
    id: string;
    searchId: string;
  };

  const [showSearchHistory, setShowSearchHistory] = useState(false); // open search history drawer
  const handleShowSearchHistory = () => {
    setShowSearchHistory(!showSearchHistory);
  };

  const { isLoadingHistory, errorGetHistory } = useProfileSearchHistory(caseId);
  const { errorSearch } = useProfileSearchResults(caseId, searchId);

  if (isLoadingHistory) {
    return <GenericLoadingPage />;
  }

  if (
    (errorGetHistory && isNotFoundError(errorGetHistory)) ||
    (errorSearch && isNotFoundError(errorSearch))
  ) {
    return <ErrorPage />;
  }

  if (errorGetHistory) {
    return <GenericLoadingPage />;
  }

  return (
    <div className="md:grid md:grid-cols-[280px_1fr] xl:grid-cols-[300px_1fr]">
      <aside className="fixed top-16 z-30 h-main max-w-max sm:max-w-full md:sticky md:block bg-white border-r ">
        <SearchHistory
          className={cn(!showSearchHistory && "hidden", "md:block")}
        />
        <Button
          className="md:hidden absolute w-10 top-2 -right-12 p-2"
          variant="outline"
          onClick={handleShowSearchHistory}
          size="icon"
        >
          <History />
        </Button>
      </aside>
      <SearchPageMain>
        <Outlet />
      </SearchPageMain>
    </div>
  );
};
