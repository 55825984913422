import moment from "moment";

const colDateFormat = "lll"; // formats to e.g. Aug 4, 2024 8:09 PM

const formatDatetime = (isoString: string, fromNow = false) => {
  if (!fromNow) {
    return moment(isoString).local().format(colDateFormat);
  } else {
    return moment(isoString).fromNow();
  }
};

const isValidDatetime = (isoString: string) => {
  return moment(isoString, moment.ISO_8601).isValid();
};

const isValidDate = (dateString: string, format: string) => {
  return moment(dateString, format).isValid();
};

export const sortByDate = <T extends { created_datetime: string }>(
  arr: T[]
) => {
  return arr.sort(
    (a, b) =>
      moment(b.created_datetime).valueOf() -
      moment(a.created_datetime).valueOf()
  );
};

export { formatDatetime, isValidDatetime, isValidDate };
