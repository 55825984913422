import { cn } from "@/lib/utils/tailwind";
import { MediaType } from "@/api/services/artifact";
import { FileX2, ImagesIcon, Link } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { useGetImageAsBase64 } from "@/components/hooks/image";

export const MediaPreview = ({
  url,
  mediaType,
  multiple,
}: {
  mediaType: MediaType;
  url: string | undefined;
  multiple: boolean;
}) => {
  const defaultStyle = "w-full rounded-sm object-cover aspect-square";
  const { base64Image } = useGetImageAsBase64(url);

  return (
    <div className="relative">
      {!url && ( // display broken link
        <div className={defaultStyle}>
          <FileX2 className="size-4 m-auto" />
        </div>
      )}
      {url &&
        base64Image &&
        mediaType === MediaType.IMAGE && ( // display image
          <img src={base64Image} alt="" className={cn(defaultStyle)} />
        )}
      {url &&
        mediaType === MediaType.VIDEO && ( // display video
          <video src={url} className={cn(defaultStyle)} />
        )}
      {multiple && ( // add multiple images icon on top-right
        <ImagesIcon className="absolute top-1 right-1 bg-black/65 px-1 rounded-sm text-gray-50 text-xs size-5" />
      )}
    </div>
  );
};

export const MediaItem = ({
  url,
  alt,
  mediaType,
}: {
  url: string | undefined;
  alt: string;
  mediaType: MediaType;
}) => {
  const { base64Image } = useGetImageAsBase64(url);

  const defaultStyle = "w-auto h-auto mx-auto max-h-[480px] max-w-[520px]";
  if (!url) {
    return (
      <div
        className={cn(
          defaultStyle,
          "flex flex-col items-center justify-center space-y-4"
        )}
      >
        <FileX2 className="size-8" />
        <p>
          <span>Media is unplayable. Visit </span>
          <a href={alt} target="_blank" className="underline text-primary">
            link
          </a>
          <span> to view.</span>
        </p>
      </div>
    );
  } else if (url && mediaType === MediaType.IMAGE) {
    return <img src={base64Image} className={cn(defaultStyle)} alt="" />;
  } else {
    return <video src={url} className={cn(defaultStyle)} controls />;
  }
};

export const LinkItem = ({
  link,
  disabled,
}: {
  link: string;
  disabled: boolean;
}) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
  };

  return (
    <a target="_blank" href={link} onClick={handleClick}>
      <Badge
        variant="outline"
        className={cn(
          disabled && "pointer-events-none",
          !disabled && "hover:bg-secondary"
        )}
      >
        <Link className="size-3 mr-2" />
        <p className="line-clamp-1 break-all font-normal">{link}</p>
      </Badge>
    </a>
  );
};
