import { useState } from "react";

import { callMsGraph } from "@/api/services/graph";

import { useMsal } from "@azure/msal-react";
import { ProtectedTemplate } from "@/pages/ProtectedTemplate";

const ProfileData = (props: any) => {
  return (
    <div id="profile-div">
      <p>
        <strong>First Name: </strong> {props.graphData.givenName}
      </p>
      <p>
        <strong>Last Name: </strong> {props.graphData.surname}
      </p>
      <p>
        <strong>Email: </strong> {props.graphData.userPrincipalName}
      </p>
      <p>
        <strong>Id: </strong> {props.graphData.id}
      </p>
    </div>
  );
};

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
export const TestAuthPage = () => {
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState();

  function RequestProfileData() {
    callMsGraph().then((response: any) => setGraphData(response));
  }

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  return (
    <>
      <ProtectedTemplate>
        <h5 className="card-title">Welcome {accounts[0]?.name}</h5>
        <br />
        {graphData ? (
          <ProfileData graphData={graphData} />
        ) : (
          <button onClick={RequestProfileData}>
            Request Profile Information
          </button>
        )}
        <button onClick={() => handleLogout()}>Logout</button>
      </ProtectedTemplate>
    </>
  );
};
