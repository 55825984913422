import { cn } from "@/lib/utils/tailwind";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { formatDatetime } from "@/lib/utils/datetime";

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { Hyperlink } from "@/components/ui/link";
import { Skeleton } from "@/components/ui/skeleton";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

import {
  ArrowDownToDot,
  ArrowUpFromDot,
  ChevronsUpDown,
  CircleDollarSign,
  ClockArrowUp,
  Hash,
  ListFilter,
  OctagonX,
} from "lucide-react";
import { useGetCryptoArtifact } from "@/features/artifacts/crypto/hooks";
import {
  TransactionAddresses,
  TransactionDirectionBadge,
  TransactionField,
  TransactionFieldText,
} from "@/features/artifacts/crypto/Transaction";

import {
  CategoryBadge,
  CategoryInfo,
  filterByCategory,
  getTransactionCategories,
} from "@/features/artifacts/crypto/Category";

import {
  ArtifactStatus,
  CryptoAddress,
  SuspiciousLevel,
} from "@/api/services/artifact";

interface CollapsibleAddressesProps {
  relevantAddress: string;
  senderAddresses: CryptoAddress[];
  receiverAddresses: CryptoAddress[];
}

const CollapsibleAddresses: React.FC<CollapsibleAddressesProps> = ({
  relevantAddress,
  senderAddresses,
  receiverAddresses,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Toggle both content sections when either trigger is clicked
  const toggleBoth = () => {
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <>
      {/* FROM ADDRESSES */}
      <Collapsible
        className="w-full"
        open={isExpanded}
        onOpenChange={toggleBoth}
      >
        <TransactionField>
          <ArrowUpFromDot className="mr-2" />
          <CollapsibleTrigger>
            <TransactionFieldText className="flex flex-row items-center space-x-1">
              <span className="shrink-0">From </span>
              <TransactionAddresses
                relevantAddress={relevantAddress}
                addresses={senderAddresses}
                variant="collapsed"
              />
              <ChevronsUpDown className="size-4 text-sky-800" />
            </TransactionFieldText>
          </CollapsibleTrigger>
        </TransactionField>
        <CollapsibleContent className="mx-2 mt-2">
          <TransactionAddresses
            relevantAddress={relevantAddress}
            addresses={senderAddresses}
            variant="expanded"
          />
        </CollapsibleContent>
      </Collapsible>
      {/* TO ADDRESSES */}
      <Collapsible
        className="w-full"
        open={isExpanded}
        onOpenChange={toggleBoth}
      >
        <TransactionField>
          <ArrowDownToDot className="mr-2" />
          <CollapsibleTrigger className="flex flex-row items-center space-x-1">
            <TransactionFieldText className="flex flex-row items-center space-x-1">
              <span className="shrink-0">To </span>
              <TransactionAddresses
                relevantAddress={relevantAddress}
                addresses={receiverAddresses}
                variant="collapsed"
              />
              <ChevronsUpDown className="size-4 text-sky-800" />
            </TransactionFieldText>
          </CollapsibleTrigger>
        </TransactionField>
        <CollapsibleContent className="mx-2 mt-2">
          <TransactionAddresses
            relevantAddress={relevantAddress}
            addresses={receiverAddresses}
            variant="expanded"
          />
        </CollapsibleContent>
      </Collapsible>
    </>
  );
};

export const SearchResults = () => {
  const { id: caseId, artifactId } = useParams() as {
    id: string;
    artifactId: string | undefined;
  };

  const { artifact } = useGetCryptoArtifact(caseId, artifactId);
  const [selectedCategories, setSelectedCategories] = useState<CategoryInfo[]>(
    []
  );

  if (!artifactId) {
    return <></>;
  }

  // display skeleeton if artifact is still processing
  if (
    !artifact ||
    artifact.status === ArtifactStatus.PROCESSING ||
    artifact.contents === undefined
  ) {
    return (
      <div className="border-b w-full h-8">
        <Skeleton className="w-1/2 md:w-1/4 h-4 mr-auto" />
      </div>
    );
  }

  // display error message if artifact failed to download
  if (artifact.status === ArtifactStatus.FAILED) {
    return (
      <Alert>
        <OctagonX className="mr-2" />
        <AlertTitle>Oh no! 😥</AlertTitle>
        <AlertDescription>This search could not be processed.</AlertDescription>
      </Alert>
    );
  }

  const metadata = artifact.metadata;
  const chain = artifact.platform;
  const transactions = artifact.contents;
  const filteredTransactions = filterByCategory(
    transactions,
    selectedCategories.map((category) => category.category)
  );

  const isSelectedCategory = (categoryInfo: CategoryInfo) => {
    return selectedCategories.some(
      (selectedCategory) => selectedCategory.category === categoryInfo.category
    );
  };

  const handleCategoryClick = (
    event: React.MouseEvent<HTMLDivElement>,
    categoryInfo: CategoryInfo
  ) => {
    if (isSelectedCategory(categoryInfo)) {
      setSelectedCategories(
        selectedCategories.filter(
          (selectedCategory) =>
            selectedCategory.category !== categoryInfo.category
        )
      );
    } else {
      setSelectedCategories([...selectedCategories, categoryInfo]);
    }
  };

  return (
    <div className="w-full flex flex-col space-y-4 h-full overflow-y-auto overflow-x-hidden">
      <p className="border-b text-muted-foreground pb-2 px-2 flex flex-row items-center">
        <span className="shrink-0">
          Showing {transactions.length} transaction(s) for&nbsp;
        </span>
        <span className="text-slate-600 font-semibold">
          {metadata.blockchain_address}
        </span>
        <span className="shrink-0">&nbsp;on</span>
        <span className="shrink-0 text-slate-600 font-bold">
          &nbsp;{chain.charAt(0).toUpperCase() + chain.slice(1)}
        </span>
      </p>
      <div className="flex flex-row space-x-2">
        <ListFilter className="mt-0.5" />
        <div className="flex flex-row gap-x-2 items-center flex-wrap gap-y-2">
          {getTransactionCategories(transactions).map((categoryInfo) => (
            <CategoryBadge
              variant={categoryInfo.suspiciousLevel}
              state={isSelectedCategory(categoryInfo) ? "active" : "inactive"}
              className={cn("cursor-pointer max-w-fit")}
              onClick={(event) => {
                handleCategoryClick(event, categoryInfo);
              }}
            >
              {categoryInfo.category}
            </CategoryBadge>
          ))}
        </div>
      </div>
      <div className="flex flex-col space-y-3">
        {filteredTransactions.map((transaction, index) => (
          <div
            className={cn(
              "flex flex-col space-y-2 p-4 rounded-none border-0 shadow-none",
              transaction.suspiciousLevel === SuspiciousLevel.HIGH &&
                "bg-red-50",
              transaction.suspiciousLevel === SuspiciousLevel.MEDIUM &&
                "bg-amber-50",
              transaction.suspiciousLevel === SuspiciousLevel.LOW && "bg-white"
            )}
          >
            {/* TRANSACTION HASH */}
            <TransactionField>
              <Hash className="mr-2" />
              <TransactionFieldText className="font-medium">
                <Hyperlink url={transaction.trmAppUrl}>
                  {transaction.transactionHash}
                </Hyperlink>
              </TransactionFieldText>
            </TransactionField>
            {/* TRANSACTION TIMESTAMP */}
            <TransactionField>
              <ClockArrowUp className="mr-2" />
              <TransactionFieldText>
                {formatDatetime(transaction.transactionTimestamp)}
              </TransactionFieldText>
            </TransactionField>
            {/* TRANSACTION RELEVANT AMOUNT */}
            <TransactionField>
              <CircleDollarSign className="mr-2" />
              <TransactionFieldText className="flex flex-row items-center space-x-2">
                {/* TRANSACTION RELEVANT AMOUNT - RAW */}
                <span>
                  {transaction.relevantAmountRaw} {transaction.assetDisplayName}
                </span>
                <TransactionDirectionBadge
                  relevantAddress={metadata.blockchain_address}
                  senderAddresses={transaction.senderAddresses}
                ></TransactionDirectionBadge>
                {/* TRANSACTION RELEVANT AMOUNT - USD */}
                <span className="text-muted-foreground">
                  {transaction.relevantAmountUsd !== null
                    ? `( = ${transaction.relevantAmountUsd} USD)`
                    : "( - )"}
                </span>
              </TransactionFieldText>
            </TransactionField>
            <div className="flex flex-col lg:flex-row justify-items-stretch space-y-2 lg:space-y-0">
              <CollapsibleAddresses
                relevantAddress={metadata.blockchain_address}
                senderAddresses={transaction.senderAddresses}
                receiverAddresses={transaction.receiverAddresses}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
