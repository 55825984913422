import { formatDatetime } from "@/lib/utils/datetime";

import { Badge } from "@/components/ui/badge";
import { Card, CardDescription, CardHeader } from "@/components/ui/card";
import { useState } from "react";
import { ModalPopup } from "@/components/ui/modal-popup";
import {
  Carousel,
  CarouselContent,
  CarouselCount,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { Repeat2, ChevronDown, ChevronUp, X } from "lucide-react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { Button } from "@/components/ui/button";
import {
  LinkItem,
  MediaItem,
  MediaPreview,
} from "@/features/artifacts/profile/Attachments";
import {
  ProcessedPost,
  ProcessedPostContent,
} from "@/features/artifacts/profile/hooks";
import { cn } from "@/lib/utils/tailwind";
import { Hyperlink } from "@/components/ui/link";

const PostContentText = ({
  owners,
  text,
  links,
  datetime,
  preview,
}: {
  owners: ProcessedPostContent["owners"];
  text: ProcessedPostContent["text"];
  links: ProcessedPostContent["links"];
  datetime: ProcessedPostContent["datetime"];
  preview: boolean;
}) => {
  return (
    <div className="flex flex-col space-y-3">
      {owners.length > 0 && (
        <p
          className={cn(
            "text-muted-foreground text-sm",
            preview && "line-clamp-1 break-all"
          )}
        >
          {owners.join(" · ")}
        </p>
      )}
      {text.length > 0 && (
        <p
          className={cn(
            "text-sm whitespace-pre-wrap",
            preview && "line-clamp-2 break-all"
          )}
        >
          {text}
        </p>
      )}
      {links.map((link) => (
        <LinkItem link={link.original_url} disabled={false} />
      ))}
      {datetime && (
        <Badge
          variant="secondary"
          className="rounded-sm capitalize justify-item-end"
        >
          {formatDatetime(datetime)}
        </Badge>
      )}
    </div>
  );
};

const PostMediaCarousel = ({
  postMedia,
}: {
  postMedia: ProcessedPostContent["media_content"];
}) => {
  return (
    <>
      {postMedia.length > 0 && (
        <Carousel className="mt-4 mb-1">
          <CarouselContent className="border-b border-t mb-1">
            {postMedia?.map((media, index) => {
              return (
                <CarouselItem>
                  <MediaItem
                    key={index}
                    url={media.url}
                    alt={media.original_url}
                    mediaType={media.type}
                  />
                </CarouselItem>
              );
            })}
          </CarouselContent>
          <div className="flex justify-between pt-2 pb-3 pr-2">
            <div>
              <CarouselPrevious className="mr-2" />
              <CarouselNext />
            </div>
            <CarouselCount />
          </div>
        </Carousel>
      )}
    </>
  );
};

const ExpandedPost = ({
  postDetails,
  closeExpandedPost,
}: {
  postDetails: ProcessedPost;
  closeExpandedPost: () => void;
}) => {
  const repostedContent = postDetails.reposted_content as ProcessedPostContent;
  return (
    <>
      <Button
        className="max-w-fit ml-auto block"
        size="sm"
        variant="back"
        onClick={closeExpandedPost}
      >
        <X className="size-5" />
      </Button>
      <PostMediaCarousel postMedia={postDetails.media_content} />
      <PostContentText
        owners={postDetails.owners}
        text={postDetails.text}
        links={postDetails.links}
        datetime={postDetails.datetime}
        preview={false}
      />
      {Object.keys(postDetails.reposted_content).length > 1 && (
        <Card className="px-4 py-4 mt-4 bg-secondary/30">
          <div className="flex flex-row gap-x-2 w-full mb-3">
            <Repeat2 className="size-4 my-auto" />
            <CardDescription className="my-auto text-xs text-slate-800">
              Reposted
            </CardDescription>
          </div>
          <PostMediaCarousel postMedia={repostedContent.media_content} />
          <PostContentText
            owners={repostedContent.owners}
            text={repostedContent.text}
            links={repostedContent.links}
            datetime={repostedContent.datetime}
            preview={false}
          />
        </Card>
      )}
    </>
  );
};

const CardPostContent = ({
  postDetails,
}: {
  postDetails: ProcessedPostContent;
}) => {
  const postMedia = postDetails.media_content;

  return (
    <div className="grid grid-cols-6 lg:grid-cols-8 gap-x-6">
      <CardHeader className="col-start-1 col-end-6 lg:col-end-8 p-0 justify-between">
        <PostContentText
          owners={postDetails.owners}
          text={postDetails.text}
          links={postDetails.links}
          datetime={postDetails.datetime}
          preview={true}
        />
      </CardHeader>
      {postMedia.length > 0 && (
        <div className="relative col-start-6 lg:col-start-8">
          <MediaPreview
            url={postMedia[0].url}
            mediaType={postMedia[0].type}
            multiple={postMedia.length > 1}
          ></MediaPreview>
        </div>
      )}
    </div>
  );
};

export const CardPost = ({ postDetails }: { postDetails: ProcessedPost }) => {
  const [expand, setExpand] = useState(false);
  const [open, setOpen] = useState(false);

  const closeExpandedPost = () => {
    setExpand(false);
  };

  if (postDetails.error_message) {
    const errorPostContent = {
      owners: postDetails.owners,
      text: "",
      datetime: postDetails.datetime,
      media_content: [],
      links: [],
    };

    return (
      <Card className="p-5 h-fit bg-red-50 flex flex-col space-y-3">
        {errorPostContent.owners.length > 0 && errorPostContent.datetime && (
          <CardPostContent postDetails={errorPostContent} />
        )}
        <div className="flex space-x-1">
          <span className="italic text-xs">{postDetails.error_message}</span>
          <Hyperlink url={postDetails.source} className="italic text-xs">
            Source
          </Hyperlink>
        </div>
      </Card>
    );
  }

  return (
    <div className="cursor-pointer">
      {expand && (
        <ModalPopup onClose={() => setExpand(false)}>
          <ExpandedPost
            postDetails={postDetails}
            closeExpandedPost={closeExpandedPost}
          ></ExpandedPost>
        </ModalPopup>
      )}
      <Card className="p-5 h-fit flex flex-col space-y-3">
        <div onClick={() => setExpand(true)}>
          <CardPostContent postDetails={postDetails} />
        </div>
        {Object.keys(postDetails.reposted_content).length > 1 && (
          <Card className="bg-secondary/30 pb-0">
            <Collapsible open={open} onOpenChange={setOpen}>
              <CollapsibleTrigger
                className={cn(
                  "flex flex-row gap-x-2 w-full justify-center px-3 py-2"
                )}
              >
                <Repeat2 className="size-4 text-slate-800" />
                <CardDescription className="text-xs text-slate-800">
                  Reposted
                </CardDescription>
                {open ? (
                  <ChevronUp className="ml-auto size-4 mb-2" />
                ) : (
                  <ChevronDown className="ml-auto size-4" />
                )}
              </CollapsibleTrigger>
              <CollapsibleContent className="pb-4 px-4">
                <CardPostContent
                  postDetails={
                    postDetails.reposted_content as ProcessedPostContent
                  }
                />
              </CollapsibleContent>
            </Collapsible>
          </Card>
        )}
        <Hyperlink url={postDetails.source} className="italic text-xs">
          Source
        </Hyperlink>
      </Card>
    </div>
  );
};

export const PostGrid = ({ posts }: { posts: ProcessedPost[] | undefined }) => {
  return (
    <div className="flex flex-col gap-y-3 max-w-4xl mx-auto">
      {posts?.map((post, index) => {
        return <CardPost key={index} postDetails={post}></CardPost>;
      })}
    </div>
  );
};
