import { useQuery } from "@tanstack/react-query";
import { artifactsQueryKey, casesQueryKey } from "@/lib/utils/queryKeys";

import { CryptoArtifact, getArtifact } from "@/api/services/artifact";

const SEARCH_RESULTS_REFRESH = 2000;

export const useGetCryptoArtifact = (
  caseId: string,
  artifactId: string | undefined
) => {
  const {
    data: artifact,
    isLoading: isLoadingArtifact,
    isError: isErrorArtifact,
    error: errorGetArtifact,
  } = useQuery({
    queryKey: [casesQueryKey, caseId, artifactsQueryKey, artifactId],
    queryFn: async () => {
      return (await getArtifact(
        caseId,
        artifactId as string
      )) as CryptoArtifact;
    },
    enabled: !!artifactId,
    refetchInterval: SEARCH_RESULTS_REFRESH,
    refetchIntervalInBackground: true,
  });

  return { artifact, isLoadingArtifact, isErrorArtifact, errorGetArtifact };
};
