import { cn } from "@/lib/utils/tailwind";
import { cva, VariantProps } from "class-variance-authority";

const logoVariant = cva(
  "rounded-sm border bg-neutral-100 flex items-center justify-center p-1",
  {
    variants: {
      size: {
        default: "size-12",
        sm: "size-8",
      },
    },
    defaultVariants: {
      size: "default",
    },
  }
);

export interface LogoProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof logoVariant> {}

export const Logo = ({
  className,
  size,
  ...props
}: LogoProps & { isStatus?: boolean }) => {
  return (
    <div className={cn(logoVariant({ size }), className)} {...props}>
      {props.children}
    </div>
  );
};
