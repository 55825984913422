import { Button } from "@/components/ui/button";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { DataTable } from "@/components/ui/data-table";
import { H1, H2 } from "@/components/ui/typography";
import { formatDatetime } from "@/lib/utils/datetime";
import {
  Archive,
  ChevronLeftIcon,
  SquareArrowOutUpRightIcon,
} from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { columns } from "@/features/artifacts/tableColumns";
import { Badge } from "@/components/ui/badge";
import { Case, CaseStatus } from "@/api/services/case";
import { useGetCase } from "@/features/cases/hooks";
import { useGetArtifacts } from "@/features/artifacts/hooks";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { GenericLoadingPage } from "@/pages/LoadingPage";
import { ErrorPage } from "@/pages/ErrorPage";
import { isNotFoundError } from "@/api/utils/errors";
import { ArtifactStatus } from "@/api/services/artifact";
import { cn } from "@/lib/utils/tailwind";
import { Logo } from "@/components/ui/logo";
import OpenSearchLogo from "@/assets/open-search-logo.svg";
import CryptoSearchLogo from "@/assets/crypto-search-logo.svg";

const HeaderContent = (caseData: Case) => {
  return (
    <>
      {caseData.status === CaseStatus.Archived && (
        <Alert className="shadow-sm mb-4">
          <Archive className="size-4 mr-2" />
          <AlertTitle>This case has been archived.</AlertTitle>
          <AlertDescription>
            You no longer have access to exported data.
          </AlertDescription>
        </Alert>
      )}
      <div className="flex justify-between items-center flex-wrap-reverse gap-y-2 sm:flex-none">
        <H1 className="w-full sm:w-fit">{caseData.report_number}</H1>
        <Badge variant="secondary">
          {formatDatetime(caseData.created_datetime)}
        </Badge>
      </div>
      <p className="text-slate-700">{caseData.description}</p>
    </>
  );
};

const ToolCardClassName = "w-full flex px-4 py-6 space-x-4 cursor-pointer";
const ToolLinkIconClassName = "self-start size-4 shrink-0";
const ToolHeaderClassName = "grow p-0 space-y-2 mt-1";

export const CaseSummary = () => {
  const navigate = useNavigate();
  const { id: caseId } = useParams() as { id: string };

  const { caseData, isLoadingCase, errorGetCase } = useGetCase(caseId);
  const { artifacts, isLoadingArtifacts, isErrorArtifacts } =
    useGetArtifacts(caseId);

  if (isLoadingCase) {
    return <GenericLoadingPage />;
  }

  if (errorGetCase && isNotFoundError(errorGetCase)) {
    return <ErrorPage />;
  }

  if (errorGetCase) {
    return <GenericLoadingPage />;
  }

  return (
    <div className="container px-4 sm:px-8 md:px-12 lg:px-16 py-8 md:py-12">
      <Button
        className="px-0 sm:px-0"
        variant="back"
        onClick={() => {
          navigate("/");
        }}
      >
        <ChevronLeftIcon className="h-4 w-4 mr-2" />
        Dashboard
      </Button>
      <main className="ml-1 flex flex-col space-y-6 lg:space-y-8 mt-4">
        <header className="flex flex-col space-y-1">
          {caseData && HeaderContent(caseData)}
        </header>
        {caseData?.status !== CaseStatus.Archived && (
          <section className="flex flex-col space-y-2 lg:space-y-0 lg:flex-row lg:space-x-2">
            <Card
              className={cn(ToolCardClassName)}
              onClick={() => {
                navigate("open-search");
              }}
            >
              <Logo>
                <img src={OpenSearchLogo} alt="OpenSearch Logo" />
              </Logo>
              <CardHeader className={cn(ToolHeaderClassName)}>
                <CardTitle>OpenSearch</CardTitle>
                <CardDescription>
                  Search for identities on social media platforms
                </CardDescription>
              </CardHeader>
              <SquareArrowOutUpRightIcon
                className={cn(ToolLinkIconClassName)}
              />
            </Card>
            <Card
              className={cn(ToolCardClassName)}
              onClick={() => navigate("crypto-search")}
            >
              <Logo>
                <img src={CryptoSearchLogo} alt="CryptoSearch Logo" />
              </Logo>
              <CardHeader className={cn(ToolHeaderClassName)}>
                <CardTitle>CryptoSearch</CardTitle>
                <CardDescription>
                  Uncover blockchain transactions
                </CardDescription>
              </CardHeader>
              <SquareArrowOutUpRightIcon
                className={cn(ToolLinkIconClassName)}
              />
            </Card>
          </section>
        )}
        <section>
          <H2>📥 Exports</H2>
          <DataTable
            columns={columns}
            data={artifacts}
            isLoading={isLoadingArtifacts}
            isError={isErrorArtifacts}
            initialSortedCol="created_datetime"
            defaultMessage="No saved artifacts."
            hiddenColIds={["artifact_id"]}
            resourceColumnId="artifact_id"
            handleRowClick={(event, resource_id, rowData) => {
              const row = rowData.original;
              row.status === ArtifactStatus.DOWNLOADED &&
                navigate(`/cases/${caseId}/artifacts/${resource_id}`);
            }}
          />
        </section>
      </main>
    </div>
  );
};
