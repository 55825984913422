import { Logo } from "@/components/ui/logo";

export const SearchPageMain = ({
  children,
  ...props
}: React.HtmlHTMLAttributes<HTMLDivElement>) => {
  return (
    <main className="h-main container px-4 sm:px-8 md:px-12 pt-12 lg:px-16 flex flex-col items-center space-y-2 sm:space-y-4 overflow-hidden">
      {children}
    </main>
  );
};

interface SearchPageLogoProps extends React.HTMLProps<HTMLDivElement> {
  logo: React.ReactNode;
  appTitle: string;
  appCaption: string;
}

export const SearchPageLogo: React.FC<SearchPageLogoProps> = ({
  logo,
  appTitle,
  appCaption,
  children,
  ...props
}) => {
  return (
    <div
      className="flex flex-col items-center space-y-3 mb-3 lg:mb-5 text-center"
      {...props}
    >
      <Logo>{logo}</Logo>
      <h1 className="text-2xl font-bold">{appTitle}</h1>
      <h2 className="text-slate-500 text-xl font-semibold">{appCaption}</h2>
      {children}
    </div>
  );
};
