import { cn } from "@/lib/utils/tailwind";

import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { PlusCircle } from "lucide-react";

import { Button } from "@/components/ui/button";
import { DataTable } from "@/components/ui/data-table";
import { H1, H2 } from "@/components/ui/typography";
import { ModalPopup } from "@/components/ui/modal-popup";

import { columns } from "@/features/cases/tableColumns";
import { CaseFormBody } from "@/features/cases/CaseFormBody";
import { CaseFormSuccess } from "@/features/cases/CaseFormSuccess";

import { useGetCases, useSubmitCase } from "@/features/cases/hooks";
import { MainLayout } from "@/layout/MainLayout";

export const Dashboard = () => {
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false); // display modal popup

  const { cases, isLoadingCases, isErrorCases } = useGetCases();
  const {
    submitCase,
    submitCaseReset,
    submitCaseSuccess,
    submitCasePending,
    submitCaseErrors,
  } = useSubmitCase();

  const modalCloseHandler = () => {
    if (showForm && !submitCasePending) {
      setShowForm(false);
      submitCaseReset(); // resets all states when modal closed
    }
  };

  const handleRowClick = (case_id: string, config: { state: object }) => {
    navigate(`cases/${case_id}`, config);
  };

  return (
    <MainLayout>
      <main
        className={cn(
          submitCasePending ? "pointer-events-none" : "pointer-events-auto",
          "container px-4 sm:px-8 md:px-12 lg:px-16 py-8 md:py-12 flex flex-col space-y-8"
        )}
      >
        <H1>Dashboard</H1>
        {/* Display case table with search, sort and pagination */}
        <section>
          <div className="flex">
            <H2 className="grow self-center">All Cases</H2>
            <Button onClick={() => setShowForm(true)} size="sm">
              <PlusCircle className="w-4 h-4 mr-2" />
              Add Case
            </Button>
          </div>
          <DataTable
            columns={columns}
            data={cases}
            isLoading={isLoadingCases}
            isError={isErrorCases}
            initialSortedCol="created_datetime"
            defaultMessage="No existing cases. Create a new case to begin."
            hiddenColIds={["case_id"]}
            resourceColumnId="case_id"
            handleRowClick={(event, resource_id, rowData) => {
              const row = rowData.original;
              const config = {
                state: {
                  report_number: row.report_number,
                  description: row.description,
                  created_datetime: row.created_datetime,
                },
              };
              handleRowClick(resource_id, config);
            }}
          />
        </section>
        {showForm && (
          <ModalPopup onClose={modalCloseHandler}>
            {!submitCaseSuccess ? (
              <CaseFormBody
                isSubmitting={submitCasePending}
                submitHandler={submitCase}
                closeForm={modalCloseHandler}
                formErrors={submitCaseErrors}
              />
            ) : (
              <CaseFormSuccess closeSuccess={modalCloseHandler} />
            )}
          </ModalPopup>
        )}
      </main>
    </MainLayout>
  );
};
