import { getArtifacts } from "@/api/services/artifact";
import { useErrorToast } from "@/components/hooks/toast-status";
import { artifactsQueryKey, casesQueryKey } from "@/lib/utils/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useGetArtifacts = (caseId: string) => {
  const {
    data: artifacts,
    isLoading: isLoadingArtifacts,
    isError: isErrorArtifacts,
    error: errorGetArtifacts,
  } = useQuery({
    queryKey: [casesQueryKey, caseId, artifactsQueryKey],
    queryFn: async ({ queryKey }) => await getArtifacts(queryKey[1] as string),
  });

  useErrorToast(errorGetArtifacts);

  return { artifacts, isLoadingArtifacts, isErrorArtifacts };
};
