import { backendAxiosInstance } from "@/api/axiosInstance";
import { ENDPOINTS } from "@/api/endpoints";

export type CryptoSearchInputs = {
  case_id: string;
  blockchain_address: string;
  description: string;
};

export const postBlockchainAddress = async (
  searchInputs: CryptoSearchInputs
): Promise<string> => {
  return backendAxiosInstance
    .post(`${ENDPOINTS.backendServices.osi}/crypto-search`, searchInputs)
    .then((response) => response.data.artifact_id);
};
