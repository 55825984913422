import axios, { AxiosError } from "axios";

export enum HttpErrorStatusCode {
  InternalServerError = 500,
  BadRequest = 400,
  Unauthenticated = 401,
  NotFound = 404,
}

export type ErrorResponse = {
  message?: string;
  errors?: { [key: string]: string };
};

export class ProcessedAxiosError extends AxiosError<ErrorResponse> {
  displayMessage: string;

  constructor(error: AxiosError<ErrorResponse>) {
    const message = error.message;
    const code = error.code;
    const config = error.config;
    const request = error.request;
    const response = error.response;

    super(message, code, config, request, response);

    const status = response?.status;

    if (axios.isCancel(error) || code === "ECONNABORTED") {
      this.displayMessage = "Cannot reach the server. Please try again later.";
    } else if (status) {
      this.displayMessage = this._getHTTPErrorMessage(status);
    } else {
      this.displayMessage = "You are not connected. Please try again later.";
    }
  }

  _getHTTPErrorMessage = (status: number) => {
    if (status === HttpErrorStatusCode.InternalServerError) {
      return "Internal server error: Please try again later.";
    } else if (status === HttpErrorStatusCode.Unauthenticated) {
      return "Authentication error: Please try logging in again.";
    } else if (status === HttpErrorStatusCode.BadRequest) {
      return "Bad Request: Please check your input and retry.";
    } else if (status === HttpErrorStatusCode.BadRequest) {
      return "Page not found. Please navigate to the front page.";
    } else {
      return `HTTP Request failed with status ${status}`;
    }
  };

  getInputErrorMessage = (inputKey: string) => {
    const errorMessages = this.response?.data?.errors;
    if (errorMessages) {
      return errorMessages[inputKey];
    }
    return undefined;
  };

  isValidationErrorResponse = () => {
    return (
      this.response?.status === HttpErrorStatusCode.BadRequest &&
      this.response?.data.errors
    );
  };
}

export const isNotFoundError = (error: Error) => {
  if (
    error instanceof ProcessedAxiosError &&
    error.response?.status === HttpErrorStatusCode.NotFound
  ) {
    return true;
  } else {
    return false;
  }
};
