import {
  CryptoAddress,
  CryptoTransaction,
  SuspiciousLevel,
} from "@/api/services/artifact";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils/tailwind";
import { cva, VariantProps } from "class-variance-authority";
import { X } from "lucide-react";

export type CategoryInfo = {
  category: string;
  suspiciousLevel: SuspiciousLevel;
};

export const getUnqiueCategories = (categories: CategoryInfo[]) => {
  const uniqueCategories = Array.from(
    new Map(
      categories.map((categoryInfo) => [categoryInfo.category, categoryInfo])
    ).values()
  );

  const sortedCategories = uniqueCategories.sort((a, b) => {
    if (a.suspiciousLevel !== b.suspiciousLevel)
      return b.suspiciousLevel - a.suspiciousLevel; // sort by suspicious level
    else return a.category.localeCompare(b.category); // sort lexically within categoires of same suspicious level
  });

  return sortedCategories;
};

export const getTransactionCategories = (
  transactions: CryptoTransaction[] | undefined
) => {
  if (transactions === undefined) {
    return [];
  }
  const addresses = transactions
    .map((transaction) =>
      transaction.senderAddresses.concat(transaction.receiverAddresses)
    )
    .flat();

  const categories = addresses
    .map((address) =>
      address.entities.map((entity) => {
        return {
          category: entity.category,
          suspiciousLevel: entity.suspiciousLevel,
        } as CategoryInfo;
      })
    )
    .flat();

  return getUnqiueCategories(categories);
};

export const filterByCategory = (
  transactions: CryptoTransaction[],
  selectedCategories: string[]
) => {
  if (selectedCategories.length === 0) {
    return transactions;
  }

  const matchAddressesWithCategory = (addresses: CryptoAddress[]) => {
    return addresses.some((address) => {
      return address.entities.some((entity) => {
        return selectedCategories.some(
          (category) => category === entity.category
        );
      });
    });
  };

  const filteredTransactions = transactions.filter((transaction) => {
    return (
      matchAddressesWithCategory(transaction.senderAddresses) ||
      matchAddressesWithCategory(transaction.receiverAddresses)
    );
  });

  return filteredTransactions;
};

const categoryBadgeVariants = cva(
  "cursor-pointer min-w-fit py-0.5 transition-all line-clamp-1 break-all flex flex-row",
  {
    variants: {
      variant: {
        [SuspiciousLevel.LOW]:
          "bg-muted hover:bg-muted border-0 text-slate-800 hover:text-slate-900 hover:border-0 hover:opacity-100",
        [SuspiciousLevel.MEDIUM]:
          "bg-amber-100 hover:bg-amber-100 text-amber-800 hover:text-amber-900 border-0 hover:opacity-100",
        [SuspiciousLevel.HIGH]:
          "bg-red-200 hover:bg-red-200 text-red-800 hover:text-red-900 border-0 hover:border-0 hover:opacity-100",
      },
      state: {
        active: "opacity-100",
        inactive: "opacity-90",
      },
    },
  }
);

export interface CategoryBadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof categoryBadgeVariants> {}

export const CategoryBadge = ({
  className,
  variant,
  state,
  ...props
}: CategoryBadgeProps) => {
  return (
    <Badge
      variant="outline"
      className={cn(categoryBadgeVariants({ variant, state }))}
      {...props}
    >
      {props.children}
      {state === "active" && <X className="ml-1" width={8} />}
    </Badge>
  );
};
