import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "@/api/authConfig";
import { AuthenticationLoading } from "@/features/authentication/AuthenticationLoading";
import { AuthenticationError } from "@/features/authentication/AuthenticationError";

export const ProtectedTemplate = (props: any) => {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={AuthenticationError}
      loadingComponent={AuthenticationLoading}
    >
      {props.children}
    </MsalAuthenticationTemplate>
  );
};
