import { NavBar } from "@/layout/topNav/NavBar";

export const MainLayout = ({ children }: { children: JSX.Element }) => {
  return (
    <div className="relative flex flex-col min-h-screen">
      <NavBar />
      {children}
    </div>
  );
};
