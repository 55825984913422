import { ColumnDef } from "@tanstack/react-table";
import { fuzzySort, SortDataButton } from "@/components/ui/data-table";
import { formatDatetime } from "@/lib/utils/datetime";
import { Artifact, ArtifactStatus } from "@/api/services/artifact";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils/tailwind";

export const columns: ColumnDef<Artifact>[] = [
  {
    accessorKey: "artifact_id",
    enableSorting: false,
    enableGlobalFilter: false,
  },
  {
    id: "artifact_number",
    header: () => "#",
    cell: ({ row, table }) => {
      return (
        (table
          .getSortedRowModel()
          ?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
      );
    },
    enableSorting: false,
    enableGlobalFilter: false,
  },
  {
    accessorKey: "description",
    header: "Description",
    cell: ({ cell }) => {
      return (
        <div className="break-words line-clamp-1">
          {cell.getValue<String>()}
        </div>
      );
    },
    sortingFn: fuzzySort,
  },
  {
    accessorKey: "platform",
    header: ({ column }) => {
      return (
        <SortDataButton dataColumn={column}>Source Platform</SortDataButton>
      );
    },
    sortingFn: fuzzySort,
  },
  {
    accessorKey: "created_datetime",
    header: ({ column }) => {
      return <SortDataButton dataColumn={column}>Created On</SortDataButton>;
    },
    cell: ({ cell }) => {
      return formatDatetime(cell.getValue<string>());
    },
    sortingFn: "datetime",
  },
  {
    accessorKey: "status",
    header: ({ column }) => {
      return <SortDataButton dataColumn={column}>Status</SortDataButton>;
    },
    cell: ({ cell }) => {
      const status = cell.getValue<number>();
      let bgColor = "";
      switch (status) {
        case ArtifactStatus.PROCESSING:
          return (
            <Badge variant="progress" isStatus={true}>
              Processing
            </Badge>
          );
        case ArtifactStatus.DOWNLOADED:
          return (
            <Badge variant="success" isStatus={true}>
              Downloaded
            </Badge>
          );
        case ArtifactStatus.ARCHIVED:
          return (
            <Badge variant="archived" isStatus={true}>
              Archived
            </Badge>
          );
        case ArtifactStatus.FAILED:
          return (
            <Badge variant="destructive" isStatus={true}>
              Failed
            </Badge>
          );
        default:
          return (
            <Badge className={cn(bgColor)} isStatus={true}>
              Unknown
            </Badge>
          );
      }
    },
    sortingFn: fuzzySort,
  },
];
