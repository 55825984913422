import { ColumnDef } from "@tanstack/react-table";
import { fuzzySort, SortDataButton } from "@/components/ui/data-table";
import { formatDatetime } from "@/lib/utils/datetime";
import { Case, CaseStatus } from "@/api/services/case";
import { Badge } from "@/components/ui/badge";

export const columns: ColumnDef<Case>[] = [
  {
    accessorKey: "case_id",
    enableSorting: false,
    enableGlobalFilter: false,
  },
  {
    accessorKey: "report_number",
    header: ({ column }) => {
      return <SortDataButton dataColumn={column}>Report/IP No.</SortDataButton>;
    },
    cell: ({ cell }) => {
      return <div className="font-medium">{cell.getValue<String>()}</div>;
    },
    sortingFn: fuzzySort,
  },
  {
    accessorKey: "description",
    header: "Description",
    cell: ({ cell }) => {
      return (
        <div className="break-words line-clamp-1">
          {cell.getValue<String>()}
        </div>
      );
    },
    sortingFn: fuzzySort,
  },
  {
    accessorKey: "created_datetime",
    header: ({ column }) => {
      return <SortDataButton dataColumn={column}>Created On</SortDataButton>;
    },
    cell: ({ cell }) => {
      return formatDatetime(cell.getValue<string>());
    },
    sortingFn: "datetime",
  },
  {
    accessorKey: "last_accessed_datetime",
    header: ({ column }) => {
      return <SortDataButton dataColumn={column}>Last Activity</SortDataButton>;
    },
    cell: ({ cell }) => {
      return formatDatetime(cell.getValue<string>());
    },
    sortingFn: "datetime",
  },
  {
    accessorKey: "status",
    header: ({ column }) => {
      return <SortDataButton dataColumn={column}>Status</SortDataButton>;
    },
    cell: ({ cell }) => {
      const status = cell.getValue<String>();
      switch (status) {
        case CaseStatus.Open:
          return (
            <Badge variant="success" isStatus={true}>
              {status}
            </Badge>
          );
        case CaseStatus.Archived:
          return (
            <Badge variant="archived" isStatus={true}>
              {status}
            </Badge>
          );
        default:
          return <Badge isStatus={true}>{status}</Badge>;
      }
    },
    sortingFn: fuzzySort,
  },
];
