import { X } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { GenericLoadingPage } from "@/pages/LoadingPage";

import { Button } from "@/components/ui/button";
import { useGetCase } from "@/features/cases/hooks";
import { CaseStatus } from "@/api/services/case";
import { ProfileDisplay } from "@/features/artifacts/profile/Main";
import { useGetArtifacts } from "@/features/artifacts/hooks";
import { ArtifactType } from "@/api/services/artifact";
import { SearchResults } from "@/features/cryptoSearch/SearchResults";

export const ArtifactPage = () => {
  const navigate = useNavigate();

  const { id: caseId, artifactId } = useParams() as {
    id: string;
    artifactId: string;
  };

  const { caseData } = useGetCase(caseId);
  const { artifacts } = useGetArtifacts(caseId);
  const artifactType = artifacts?.find(
    (a) => a.artifact_id === artifactId
  )?.artifact_type;

  if (!artifacts || !caseData) {
    return <GenericLoadingPage />;
  }

  if (caseData.status === CaseStatus.Archived) {
    navigate(`/cases/${caseId}`);
  }

  return (
    <>
      <Button
        className="max-w-fit mt-8 md:mr-12 ml-auto"
        size="sm"
        variant="back"
        onClick={() => {
          navigate(-1);
        }}
      >
        <X className="!size-6" />
      </Button>
      <div className="container px-6 sm:px-8 md:px-12 lg:px-16 ">
        {artifactType === ArtifactType.PROFILE && (
          <main className="flex flex-col w-full space-y-6 h-full overflow-auto mt-4 mb-24">
            <ProfileDisplay />
          </main>
        )}
        {artifactType === ArtifactType.CRYPTO && (
          <div className="h-main w-full pb-4 lg:pb-12">
            <SearchResults />
          </div>
        )}
      </div>
    </>
  );
};
