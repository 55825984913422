import { Button } from "@/components/ui/button";
import logo from "@/assets/main-logo.png";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "@/api/authConfig";

/**
 * Renders the login page component.
 *
 * @param {Object} props - The properties passed to the component.
 * @return {JSX.Element} The rendered login page.
 */
export const Login = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };

  return (
    <>
      <div className="h-screen bg-white dark:bg-gray-900 px-8 pb-16 flex flex-col justify-center items-center space-y-8">
        <img src={logo} height={250} width={250} alt="Platform Logo"></img>
        <div className="flex flex-col space-y-4 items-center">
          <h1 className="text-4xl font-extrabold text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
            UNVEIL
          </h1>
          <p className="text-center text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">
            Quickly perform searches through multiple online platforms.
          </p>
        </div>
        <Button className="w-full sm:w-96" onClick={() => handleLogin()}>
          Login via WOG AD
        </Button>
      </div>
    </>
  );
};
