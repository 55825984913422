import { ContentType, getArtifact, MediaType, Post, PostContent, ProfileArtifact } from "@/api/services/artifact";
import { prefetchImages } from "@/components/hooks/image";
import { useErrorToast } from "@/components/hooks/toast-status";
import { artifactsQueryKey, casesQueryKey } from "@/lib/utils/queryKeys";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export type ProcessedPostContent = {
  links: {
    original_url: string;
    type: MediaType;
  }[];
} & PostContent;

export type ProcessedPost = {
  error_message: string;
  source: string;
  type: ContentType;
  reposted_content: ProcessedPostContent | {};
} & ProcessedPostContent;

export type ProcessedProfileArtifact = Omit<ProfileArtifact, "contents"> & {
  contents: Record<ContentType, ProcessedPost[]>;
};

export const useGetProfile = (caseId: string, artifactId: string) => {
  const queryClient = useQueryClient();

  const processPost = (post: Post) => {
    // differentiate between links and media
    const postLinks = post.media_content.filter(
      (item) => item.type === MediaType.LINK
    );

    const postMedia = post.media_content.filter(
      (item) => item.type !== MediaType.LINK
    );

    // filter and prefetch all images
    const postImages = post.media_content.filter(
      (item) => item.type === MediaType.IMAGE && item.url
    );

    prefetchImages(
      queryClient,
      postImages.map((item) => item.url as string)
    );

    if (Object.keys(post.reposted_content).length === 0) {
      return {
        ...post,
        media_content: postMedia,
        links: postLinks,
        reposted_content: {},
      };
    } else {
      // differentiated reposted links and media
      const repostedLinks = (
        post.reposted_content as PostContent
      ).media_content.filter((item) => item.type === MediaType.LINK);

      const repostedMedia = (
        post.reposted_content as PostContent
      ).media_content.filter((item) => item.type !== MediaType.LINK);

      // prefetch all images
      const repostedImages = (
        post.reposted_content as PostContent
      ).media_content.filter(
        (item) => item.type === MediaType.IMAGE && item.url
      );

      prefetchImages(
        queryClient,
        repostedImages.map((item) => item.url as string)
      );

      return {
        ...post,
        media_content: postMedia,
        links: postLinks,
        reposted_content: {
          ...post.reposted_content,
          media_content: repostedMedia,
          links: repostedLinks,
        },
      };
    }
  };

  const filterPosts = (allPosts: Post[]) => {
    // group posts by content type, e.g. posts, reels, stories
    return allPosts.reduce<Record<ContentType, ProcessedPost[]>>(
      (groupedPosts, post) => {
        if (!groupedPosts[post.type]) {
          groupedPosts[post.type] = [];
        }
        const processedPost = processPost(post);
        groupedPosts[post.type].push(processedPost);
        return groupedPosts;
      },
      {} as Record<ContentType, ProcessedPost[]>
    );
  };

  const {
    data: artifact,
    isLoading: isLoadingArtifact,
    isError: isErrorArtifact,
    error: errorGetArtifact,
  } = useQuery({
    queryKey: [casesQueryKey, caseId, artifactsQueryKey, artifactId],
    queryFn: async () => {
      const profileArtifact = (await getArtifact(
        caseId,
        artifactId
      )) as ProfileArtifact;
      prefetchImages(queryClient, [profileArtifact.metadata.profile_pic]);
      const filteredPosts = filterPosts(profileArtifact.contents);
      return {
        ...profileArtifact,
        contents: filteredPosts,
      } as ProcessedProfileArtifact;
    },
  });

  useErrorToast(errorGetArtifact);

  return { artifact, isLoadingArtifact, isErrorArtifact };
};
