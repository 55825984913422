import { postBlockchainAddress } from "@/api/services/cryptoSearch";
import {
  useBadNetworkToast,
  useErrorToast,
} from "@/components/hooks/toast-status";
import { artifactsQueryKey, casesQueryKey } from "@/lib/utils/queryKeys";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCryptoSearch = (caseId: string) => {
  const queryClient = useQueryClient();

  const {
    mutate: searchBlockchainAddress,
    data: artifactId,
    isPaused: searchAddressPaused,
    status: searchAddressStatus,
    error,
  } = useMutation({
    mutationFn: async (userInputs: { blockchain_address: string }) => {
      const searchInputs = {
        case_id: caseId,
        description: `Transaction history of ${userInputs.blockchain_address}`,
        ...userInputs,
      };
      const artifactId = await postBlockchainAddress(searchInputs);
      queryClient.setQueryData(
        [casesQueryKey, caseId, artifactsQueryKey, artifactId],
        { metadata: { blockchain_address: userInputs.blockchain_address } }
      );
      return artifactId;
    },
  });

  useErrorToast(error);
  useBadNetworkToast(searchAddressPaused);

  return {
    searchBlockchainAddress,
    artifactId,
    searchAddressStatus,
  };
};
