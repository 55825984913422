import { H1 } from "@/components/ui/typography";
import { Separator } from "@/components/ui/separator";

export const ErrorPage: React.FC = () => {
  return (
    <div className="flex grow items-center justify-center space-x-3">
      <H1>404</H1>
      <Separator orientation="vertical" className="h-8" />
      <div>This page could not be found.</div>
    </div>
  );
};
