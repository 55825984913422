import { Skeleton } from "@/components/ui/skeleton";

export const GenericLoadingPage: React.FC = () => {
  return (
    <div className="container px-6 sm:px-8 md:px-12 py-8 md:py-12 grid grid-rows-12 gap-6 grow">
      <Skeleton className="row-span-1" />
      <Skeleton className="row-span-2" />
      <Skeleton className="row-span-3" />
      <Skeleton className="row-span-4" />
    </div>
  );
};
