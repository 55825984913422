import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { UseMutateFunction } from "@tanstack/react-query";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { AxiosResponse } from "axios";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/spinner";
import { H3 } from "@/components/ui/typography";

import { CaseForm } from "@/api/services/case";
import { ProcessedAxiosError } from "@/api/utils/errors";

import { caseFormSchema } from "@/features/cases/formValidation";

type CaseFormProps = {
  isSubmitting?: boolean;
  closeForm: () => void;
  submitHandler: UseMutateFunction<
    AxiosResponse<any, any>,
    Error,
    CaseForm,
    unknown
  >;
  formErrors: Error | null;
};

export const CaseFormBody = ({
  isSubmitting,
  closeForm,
  submitHandler,
  formErrors,
}: CaseFormProps) => {
  const form = useForm<z.infer<typeof caseFormSchema>>({
    resolver: zodResolver(caseFormSchema),
    defaultValues: {
      report_number: "",
      description: "",
    },
  });

  const { setError } = form;

  useEffect(() => {
    if (formErrors && formErrors instanceof ProcessedAxiosError) {
      const report_number_error =
        formErrors.getInputErrorMessage("report_number");

      report_number_error &&
        setError("report_number", {
          type: "server",
          message: report_number_error,
        });
    }
  }, [formErrors, setError]);

  function submitForm(values: z.infer<typeof caseFormSchema>) {
    const processedValues = {
      ...values,
      report_number: values.report_number.toUpperCase(),
    };
    submitHandler(processedValues);
  }

  return (
    <div>
      <H3 className="mb-6 mt-2">Submit Case Details</H3>
      <Form {...form}>
        <form className="space-y-6" onSubmit={form.handleSubmit(submitForm)}>
          <FormField
            control={form.control}
            name="report_number"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Report/IP Number</FormLabel>
                <FormControl>
                  <Input placeholder="Enter the report/IP number" {...field} />
                </FormControl>
                <FormDescription>Example: A/20240821/0001</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Case Description</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="Enter the case description"
                    className="resize-none"
                    {...field}
                  />
                </FormControl>
                <FormDescription>
                  Example: This is a malware case.
                </FormDescription>
                <FormMessage></FormMessage>
              </FormItem>
            )}
          />
          <div className="flex flex-col space-y-2 pt-2">
            <Button variant="default" className="w-full" type="submit">
              {isSubmitting ? (
                <>
                  <Spinner
                    size="small"
                    className="text-primary-foreground mr-2"
                  />
                  Submitting...
                </>
              ) : (
                <>Submit</>
              )}
            </Button>
            <Button variant="outline" className="w-full" onClick={closeForm}>
              Cancel
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};
