import { cn } from "@/lib/utils/tailwind";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  sortingFns,
  SortingFn,
  FilterFn,
  Row,
} from "@tanstack/react-table";

import {
  RankingInfo,
  rankItem,
  compareItems,
} from "@tanstack/match-sorter-utils";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { Button } from "@/components/ui/button";
import { DebouncedInput } from "@/components/ui/input";

import {
  ArrowUpDown,
  ChevronLeft,
  ChevronRight,
  MoveDown,
  MoveUp,
  Search,
} from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { formatDatetime, isValidDatetime } from "@/lib/utils/datetime";
import { Skeleton } from "@/components/ui/skeleton";

declare module "@tanstack/react-table" {
  //add fuzzy filter to the filterFns
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

// Define a custom fuzzy filter function to apply ranking info to rows (using match-sorter utils)
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  let columnValue = row.getValue(columnId);

  if (isValidDatetime(row.getValue<string>(columnId))) {
    columnValue = formatDatetime(row.getValue<string>(columnId));
  }

  // Rank the item
  const itemRank = rankItem(columnValue, value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

// Define a custom fuzzy sort function that will sort by rank if the row has ranking information
export const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank!,
      rowB.columnFiltersMeta[columnId]?.itemRank!
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

const SortDataButton = (props: { children: string; dataColumn: any }) => {
  const sorted = props.dataColumn.getIsSorted();
  return (
    <button
      className="flex items-center text-left"
      onClick={() => props.dataColumn.toggleSorting(sorted === "asc")}
    >
      {props.children}
      {sorted === "asc" ? (
        <MoveUp className="ml-2 h-4 w-4 text-neutral-500" />
      ) : sorted === "desc" ? (
        <MoveDown className="ml-2 h-4 w-4 text-neutral-500" />
      ) : (
        <ArrowUpDown className="ml-2 h-4 w-4 text-neutral-500" />
      )}
    </button>
  );
};

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[] | undefined;
  isLoading: Boolean;
  isError: Boolean;
  initialSortedCol: string;
  hiddenColIds?: string[];
  defaultMessage?: string;
  resourceColumnId?: string;
  handleRowClick?: (
    event: React.MouseEvent<HTMLTableRowElement>,
    resource_id: string,
    data: Row<TData>
  ) => void;
}

function DataTable<TData, TValue>({
  columns,
  data,
  isLoading,
  isError,
  initialSortedCol,
  defaultMessage,
  hiddenColIds = [],
  resourceColumnId,
  handleRowClick,
}: DataTableProps<TData, TValue>) {
  const [globalFilter, setGlobalFilter] = useState("");
  const [page, setPage] = useState(1);

  // fill in empty data while loading
  const displayData = useMemo(
    () => (isLoading ? Array(5).fill({}) : isError ? [] : data),
    [data, isLoading, isError]
  );

  // format table display while loading
  const displayColumns = useMemo(
    () =>
      isLoading
        ? (columns.map((column) => ({
            ...column,
            cell: () => <Skeleton className="h-2 w-1/2 rounded-full" />,
          })) as ColumnDef<TData>[])
        : columns,
    [isLoading, columns]
  );

  const hideColumns = (columns: string[]) => {
    const visibility: { [key: string]: boolean } = {};
    columns.forEach((column) => {
      visibility[column] = false; // hide 'columnId2' by default
    });
    return visibility;
  };

  const columnVisibility = hideColumns(hiddenColIds);

  const table = useReactTable({
    data: displayData as TData[],
    columns: displayColumns,
    filterFns: {
      fuzzy: fuzzyFilter, //define as a filter function that can be used in column definitions
    },
    state: {
      globalFilter,
      columnVisibility,
    },
    initialState: {
      sorting: [
        {
          id: initialSortedCol,
          desc: true, // sort in descending order by default
        },
      ],
    },

    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: "fuzzy", //apply fuzzy filter to the global filter
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    enableSortingRemoval: true,
    autoResetPageIndex: true,
  });

  const totalPages = table.getPageCount();
  const { resetGlobalFilter } = table;

  useEffect(() => {
    resetGlobalFilter(true);
  }, [data, resetGlobalFilter]);

  return (
    <>
      <div className="flex items-center py-2 sticky top-16 bg-white z-10">
        <DebouncedInput
          value={globalFilter ?? ""}
          onChange={(value) => {
            setGlobalFilter(String(value));
            table.firstPage();
            setPage(1);
          }}
          placeholder="Quick search..."
          startIcon={Search}
        />
      </div>
      <div className="border bg-white">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="bg-slate-50">
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  onClick={
                    resourceColumnId &&
                    row.getValue(resourceColumnId) &&
                    handleRowClick
                      ? (event) =>
                          handleRowClick(
                            event,
                            row.getValue(resourceColumnId), // navigate to a page using the resource id,
                            row
                          )
                      : undefined
                  }
                  className={cn({
                    "cursor-pointer": handleRowClick !== undefined,
                  })}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  {!isError && data && data.length === 0 && defaultMessage // no existing data
                    ? defaultMessage
                    : "No results found."}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {!isLoading && totalPages > 0 && (
        <div className="flex items-center justify-center space-x-2 py-4">
          <Button
            variant="outline"
            onClick={() => {
              table.previousPage();
              setPage(page - 1);
            }}
            disabled={!table.getCanPreviousPage()}
            size="icon"
          >
            <ChevronLeft className="h-4 w-4" />
          </Button>
          <p className="text-muted-foreground pointer-events-none">
            {page} of {totalPages}
          </p>
          <Button
            variant="outline"
            onClick={() => {
              table.nextPage();
              setPage(page + 1);
            }}
            disabled={!table.getCanNextPage()}
            size="icon"
          >
            <ChevronRight className="h-4 w-4" />
          </Button>
        </div>
      )}
    </>
  );
}

export { DataTable, SortDataButton };
