import { msalInstance } from "@/index";
import { loginRequest } from "@/api/authConfig";
import { AccountInfo } from "@azure/msal-browser";
import { graphAxiosInstance } from "@/api/axiosInstance";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function callMsGraph() {
  return graphAxiosInstance.get("/").then((response) => response.data);
}

export async function forceLogout() {
  msalInstance.logoutRedirect({
    postLogoutRedirectUri: "/",
  });
}

export async function getAccessToken() {
  const account = msalInstance.getActiveAccount();
  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account as AccountInfo,
  });
  return response.accessToken;
}

export async function getIdToken() {
  const account = msalInstance.getActiveAccount();
  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account as AccountInfo,
  });
  return response.idToken;
}
