import { cn } from "@/lib/utils/tailwind";

type HeadingProps = {
  children: any;
  className?: string;
};

export const H1 = ({ children, className }: HeadingProps) => {
  return (
    <h1
      className={cn(
        "scroll-m-20 font-bold tracking-tight text-2xl xl:text-3xl flex space-x-2 items-center",
        className
      )}
    >
      {children}
    </h1>
  );
};

export const H2 = ({ children, className }: HeadingProps) => {
  return (
    <h1
      className={cn(
        "scroll-m-20 font-semibold tracking-tight text-xl xl:text-2xl flex space-x-2 items-center",
        className
      )}
    >
      {children}
    </h1>
  );
};

export const H3 = ({ children, className }: HeadingProps) => {
  return (
    <h3
      className={cn(
        "text-xl font-bold tracking-tight text-gray-900 mb-4 flex space-x-2 items-center",
        className
      )}
    >
      {children}
    </h3>
  );
};
