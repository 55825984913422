import { Routes, Route, Outlet } from "react-router-dom";
import { TestAuthPage } from "@/pages/TestAuthPage";
import { Root } from "@/pages/Root";
import { CaseSummary } from "@/pages/CaseSummary";
import { ProtectedTemplate } from "@/pages/ProtectedTemplate";
import { Toaster } from "@/components/ui/toaster";
import { ErrorPage } from "@/pages/ErrorPage";
import {
  OpenSearchLandingPage,
  OpenSearchIndex,
  OpenSearchResultsPage,
} from "@/pages/OpenSearch";
import { MainLayout } from "@/layout/MainLayout";
import { ArtifactPage } from "@/pages/ArtifactPage";
import {
  CryptoSearchIndex,
  CryptoSearchLandingPage,
  CryptoSearchResultsPage,
} from "@/pages/CryptoSearch";

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
export default function App() {
  return (
    <>
      <Toaster />
      <Routes>
        <Route path="/testProfile" element={<TestAuthPage />} />
        <Route path="/" element={<Root />} />
        <Route
          path="cases/:id"
          element={
            <ProtectedTemplate>
              <MainLayout>
                <Outlet />
              </MainLayout>
            </ProtectedTemplate>
          }
        >
          <Route index element={<CaseSummary />}></Route>
          <Route path="open-search" element={<OpenSearchIndex />}>
            <Route index element={<OpenSearchLandingPage />} />
            <Route path=":searchId" element={<OpenSearchResultsPage />} />
          </Route>
          <Route path="crypto-search" element={<CryptoSearchIndex />}>
            <Route index element={<CryptoSearchLandingPage />} />
            <Route path=":artifactId" element={<CryptoSearchResultsPage />} />
          </Route>
          <Route path="artifacts/:artifactId" element={<ArtifactPage />} />
        </Route>
        <Route
          path="*"
          element={
            <ProtectedTemplate>
              <MainLayout>
                <ErrorPage />
              </MainLayout>
            </ProtectedTemplate>
          }
        />
      </Routes>
    </>
  );
}
