import { UserDropdown } from "@/layout/topNav/UserDropdown";
import { useMsal } from "@azure/msal-react";
import { Button } from "@/components/ui/button";
import { Link, useNavigate } from "react-router-dom";
import { BookOpenText, Headset } from "lucide-react";

export const NavBar = () => {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  return (
    <header className="flex h-nav items-center justify-items-stretch px-6 py-2 border-b overflow-hidden sticky top-0 bg-white z-10">
      <h1
        className="text-2xl font-extrabold text-gray-900 dark:text-white mr-auto cursor-pointer"
        onClick={() => navigate("/")}
      >
        UNVEIL
      </h1>
      <Button asChild variant="ghost">
        <Link to="/testProfile">
          <BookOpenText className="w-4 h-4 sm:mr-2" />
          <span className="hidden sm:inline">Guides</span>
        </Link>
      </Button>
      <Button asChild variant="ghost" className="mr-2">
        <Link to="/testProfile">
          <Headset className="w-4 h-4 sm:mr-2" />
          <span className="hidden sm:inline">Support</span>
        </Link>
      </Button>
      {accounts.length > 0 ? (
        <UserDropdown
          userName={accounts[0].name}
          userEmail={accounts[0].username}
          logoutHandler={handleLogout}
        />
      ) : null}
    </header>
  );
};
