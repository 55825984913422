import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { casesQueryKey } from "@/lib/utils/queryKeys";
import { Case, getCases, getCasesbyId, postCases } from "@/api/services/case";
import {
  useBadNetworkToast,
  useErrorToast,
} from "@/components/hooks/toast-status";

export const useGetCases = () => {
  const {
    data: cases,
    isLoading: isLoadingCases,
    isError: isErrorCases,
    error: errorGetCases,
  } = useQuery({
    queryKey: [casesQueryKey],
    queryFn: getCases,
  });

  useErrorToast(errorGetCases);

  return { cases, isLoadingCases, isErrorCases, errorGetCases };
};

export const useSubmitCase = () => {
  const queryClient = useQueryClient();

  const {
    mutate: submitCase,
    reset: submitCaseReset,
    isSuccess: submitCaseSuccess,
    isPaused: submitCasePaused,
    isPending: submitCasePending,
    error: submitCaseErrors,
  } = useMutation({
    mutationFn: postCases,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [casesQueryKey] }); // invalidate cases data and refetch
    },
  });

  useErrorToast(submitCaseErrors);
  useBadNetworkToast(submitCasePaused);

  return {
    submitCase,
    submitCaseReset,
    submitCaseSuccess,
    submitCasePending,
    submitCaseErrors,
  };
};

export const useGetCase = (caseId: string) => {
  const queryClient = useQueryClient();

  const {
    data: caseData,
    error: errorGetCase,
    isLoading: isLoadingCase,
  } = useQuery({
    queryKey: [casesQueryKey, caseId],
    queryFn: async () => {
      const caseData = await getCasesbyId(caseId);
      return caseData;
    },
    initialData: () => {
      const casesCache = queryClient.getQueryData<Case[]>([casesQueryKey]);
      return casesCache?.find((c) => c.case_id === caseId);
    },
  });

  useErrorToast(errorGetCase);

  return { caseData, isLoadingCase, errorGetCase };
};
