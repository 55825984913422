import { cn } from "@/lib/utils/tailwind";
import { forwardRef, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Skeleton } from "@/components/ui/skeleton";
import { Button } from "@/components/ui/button";
import { ChevronLeft } from "lucide-react";

import { useGetCase } from "@/features/cases/hooks";
import { useProfileSearchHistory } from "@/features/openSearch/hooks";
import { Logo } from "@/components/ui/logo";
import OpenSearchLogo from "@/assets/open-search-logo.svg";

interface SearchHistoryItemProps {
  id: string;
  item_name: string;
  platform: string;
  isClicked: boolean;
  clickHandler: (id: string) => void;
}

const SearchHistoryItem = forwardRef<HTMLDivElement, SearchHistoryItemProps>(
  ({ id, item_name, platform, isClicked, clickHandler }, ref) => {
    return (
      <div
        className={cn(
          "hover:bg-slate-50 hover:text-accent-foreground",
          isClicked && "bg-accent text-accent-foreground font-medium",
          "transition-all"
        )}
        ref={ref}
        onClick={() => {
          clickHandler(id);
        }}
      >
        <div className="flex flex-col space-y-2 py-4 px-6">
          <p className="leading-none">{item_name}</p>
          <p className="text-muted-foreground">{platform}</p>
        </div>
      </div>
    );
  }
);

export const SearchHistory = ({ className }: { className?: string }) => {
  const navigate = useNavigate();

  const { id: caseId, searchId } = useParams() as {
    id: string;
    searchId: string;
  };
  const { caseData } = useGetCase(caseId);

  const { searchHistory } = useProfileSearchHistory(caseId);
  const [activeResult, setActiveResult] = useState(searchId);

  const handleClick = (id: string) => {
    setActiveResult(id);
    navigate(id);
  };

  // handle initial scrolling to active item
  const [scrollOffset, setScrollOffset] = useState<number>(0);
  const [activeItemPosition, setActiveItemPosition] = useState<number>(0);

  const measureScrollAreaOffset = useCallback(
    (element: HTMLDivElement | null) => {
      if (element && scrollOffset === 0) {
        setScrollOffset(element.getBoundingClientRect().bottom);
      }
    },
    [scrollOffset]
  );

  const measureItemPosition = useCallback(
    (element: HTMLDivElement | null, isClicked: boolean) => {
      if (isClicked && element && activeItemPosition === 0) {
        setActiveItemPosition(element.getBoundingClientRect().top);
      }
    },
    [activeItemPosition]
  );

  const scrollToElement = useCallback(
    (element: HTMLElement | null) => {
      const scrollPosition = activeItemPosition - scrollOffset;

      if (element) {
        element.scrollTo({
          top: scrollPosition,
        });
      }
    },
    [scrollOffset, activeItemPosition]
  );

  if (!caseData || !searchHistory) {
    return (
      <div className="md:w-[280px] lg:w-[300px] h-full py-6 md:py-12 md:px-6 flex flex-col space-y-8">
        <Skeleton className="w-1/2 h-4" />
      </div>
    );
  }

  return (
    <ScrollArea
      className={cn("h-full text-sm pt-6 md:pt-12 pb-2", className)}
      viewportRef={scrollToElement}
    >
      <div className="sticky top-0" ref={measureScrollAreaOffset}>
        <Button
          variant="back"
          onClick={() => navigate(`/cases/${caseId}`)}
          className="bg-white pb-4 w-full justify-start"
        >
          <ChevronLeft size={18} className="mr-2" />
          {caseData?.report_number}
        </Button>
        <Button
          variant="secondary"
          className="w-full rounded-none hover:bg-secondary/100 font-medium py-8 flex flex-row justify-start space-x-2"
          onClick={() => {
            navigate(`/cases/${caseId}/open-search`);
            setActiveResult("");
          }}
        >
          <Logo size="sm" className="ml-1">
            <img src={OpenSearchLogo} alt="OpenSearch logo" />
          </Logo>
          <div>OpenSearch</div>
        </Button>
        <header className="flex flex-col space-y-2 p-6 pb-4 backdrop-blur supports-[backdrop-filter]:bg-background/85">
          <p className="font-medium">Search History</p>
          <p className="text-muted-foreground">for {caseData?.report_number}</p>
        </header>
      </div>
      {searchHistory.map((searchHistory) => (
        <SearchHistoryItem
          ref={(el) =>
            measureItemPosition(el, activeResult === searchHistory.search_id)
          }
          key={searchHistory.search_id}
          id={searchHistory.search_id}
          item_name={searchHistory.search_entry}
          platform={searchHistory.platform}
          isClicked={activeResult === searchHistory.search_id}
          clickHandler={handleClick}
        />
      ))}
    </ScrollArea>
  );
};
