import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Dashboard } from "@/pages/Dashboard";
import { Login } from "@/pages/Login";

/**
 * If a user is authenticated the dashboard is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
export const Root = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <Dashboard />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </>
  );
};
