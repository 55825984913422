import { backendAxiosInstance } from "@/api/axiosInstance";
import { ENDPOINTS } from "@/api/endpoints";
import { sortByDate } from "@/lib/utils/datetime";
import { ArtifactStatus } from "@/api/services/artifact";

export type ProfileSearchInputs = {
  search_entry: string;
  platform: string;
  case_id: string;
};

export enum ProfileSearchStatus {
  PROCESSING = 2,
  SCRAPED = 3,
  COMPLETED = 4,
  FAILED = 5,
}

export type ProfileSearchResults = {
  search_result_id: string;
  artifact_id?: string;
  artifact_status?: ArtifactStatus;
  profile_id: string;
  display_name: string;
  profile_pic: string;
  date_joined: string;
};

export type ProfileSearchMetadata = {
  search_id: string;
  search_entry: string;
  platform: string;
  created_datetime: string;
  status: ProfileSearchStatus;
  download_count: number;
  max_download_count: number;
};

export type ProfileSearchEntry = ProfileSearchMetadata & {
  results: ProfileSearchResults[];
};

export const getProfileSearchHistory = async (
  case_id: string
): Promise<ProfileSearchMetadata[]> => {
  return backendAxiosInstance
    .get(`${ENDPOINTS.backendServices.cms}/${case_id}/profile-searches`)
    .then((response) => sortByDate(response.data));
};

export const getProfileSearchResults = async (
  case_id: string,
  search_id: string
): Promise<ProfileSearchEntry> => {
  return backendAxiosInstance
    .get(
      `${ENDPOINTS.backendServices.cms}/${case_id}/profile-searches/${search_id}`
    )
    .then((response) => response.data); // throw error if status is processing or failed here
};

export const postProfileSearch = async (
  searchInputs: ProfileSearchInputs
): Promise<string> => {
  return backendAxiosInstance
    .post(`${ENDPOINTS.backendServices.osi}/profile-search`, searchInputs)
    .then((response) => response.data.search_id);
};

export type ProfileDownloadInputs = {
  case_id: string;
  search_result_id: string;
  platform: string;
  description: string;
};

export const postProfile = async (profileFields: ProfileDownloadInputs) => {
  const url = `${ENDPOINTS.backendServices.osi}/artifact`;
  return backendAxiosInstance.post(url, profileFields);
};
