import * as React from "react";

import { cn } from "@/lib/utils/tailwind";
import { LucideIcon } from "lucide-react";

import { useState, useEffect } from "react";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  startIcon?: LucideIcon;
}

const startIconDivStyle =
  "absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none";
const inputStyle =
  "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50";

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, startIcon, ...props }, ref) => {
    const StartIcon = startIcon;

    return (
      <div className="relative w-full">
        {StartIcon ? (
          <div className={startIconDivStyle}>
            <StartIcon size={18} className="text-muted-foreground" />
          </div>
        ) : null}
        <input
          type={type}
          className={cn(inputStyle, StartIcon ? "pl-9" : "", className)}
          ref={ref}
          {...props}
        />
      </div>
    );
  }
);
Input.displayName = "Input";

const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  startIcon,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
  startIcon?: any;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) => {
  const StartIcon = startIcon;
  const [value, setValue] = useState(initialValue);

  const handleInputChange = (value: string) => {
    setValue(value);
    onChange(value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setValue(value);
    }, debounce);
    return () => clearTimeout(timeoutId);
  }, [value, debounce]);

  return (
    <div className="w-full relative">
      {StartIcon ? (
        <div className={startIconDivStyle}>
          <StartIcon size={18} className="text-muted-foreground" />
        </div>
      ) : null}
      <input
        {...props}
        value={value}
        className={cn(inputStyle, StartIcon ? "pl-9" : "")}
        onChange={(e) => handleInputChange(e.target.value)}
      />
    </div>
  );
};

export { Input, DebouncedInput };
