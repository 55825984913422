import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  useBadNetworkToast,
  useErrorToast,
} from "@/components/hooks/toast-status";
import { casesQueryKey, profileSearchQueryKey } from "@/lib/utils/queryKeys";

import {
  getProfileSearchHistory,
  getProfileSearchResults,
  postProfileSearch,
  postProfile,
  ProfileSearchStatus,
} from "@/api/services/openSearch";
import { prefetchImages } from "@/components/hooks/image";

const SEARCH_RESULTS_DELAY = 5000; // in ms
const SEARCH_RESULTS_REFRESH = 10000;

/**
 * Fetches the search history for a given caseId.
 *
 * @param {string} caseId - The ID of the case to fetch search history for.
 *
 * @returns {Object} An object with three properties: searchHistory, isLoadingHistory, errorGetHistory.
 * searchHistory is an array of SearchMetadata objects.
 * isLoadingHistory is a boolean representing whether the query is currently loading.
 * errorGetHistory is an error object if the query failed.
 */
export const useProfileSearchHistory = (caseId: string) => {
  const {
    data: searchHistory,
    isLoading: isLoadingHistory,
    error: errorGetHistory,
  } = useQuery({
    queryKey: [casesQueryKey, caseId, profileSearchQueryKey],
    queryFn: async () => {
      return getProfileSearchHistory(caseId);
    },
  });

  useErrorToast(errorGetHistory);

  return {
    searchHistory,
    isLoadingHistory,
    errorGetHistory,
  };
};

/**
 * Fetches the search results for a given caseId and searchId.
 *
 * When the search is processing, this query will refetch every SEARCH_RESULTS_DELAY milliseconds.
 * When the search is complete, this query will refetch every SEARCH_RESULTS_REFRESH milliseconds.
 *
 * This query is only enabled when the searchId is defined.
 *
 * @param {string} caseId - The ID of the case to fetch search results for.
 * @param {string | undefined} searchId - The ID of the search to fetch results for.
 *
 * @returns {Object} An object with four properties: searchResults, isfetchingResults, isLoadingResults, errorSearch.
 * searchResults is the search results object.
 * isfetchingResults is a boolean representing whether the query is currently fetching in the background.
 * isLoadingResults is a boolean representing whether the query is currently loading, and errorSearch is an error object if the query failed.
 */
export const useProfileSearchResults = (
  caseId: string,
  searchId: string | undefined
) => {
  const queryClient = useQueryClient();
  const queryKey = [casesQueryKey, caseId, profileSearchQueryKey, searchId];

  const {
    data: searchResults,
    isFetching: isfetchingResults,
    isLoading: isLoadingResults,
    error: errorSearch,
  } = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      const searchResults = await getProfileSearchResults(
        caseId,
        searchId as string
      );
      const profilePics = searchResults.results.map(
        (result) => result.profile_pic
      );
      prefetchImages(queryClient, profilePics);
      return searchResults;
    },
    refetchInterval: (query) => {
      if (
        query.state.data?.status ===
        (ProfileSearchStatus.PROCESSING || ProfileSearchStatus.SCRAPED) // keep polling until text and images are downloaded
      ) {
        return SEARCH_RESULTS_DELAY;
      } else {
        return SEARCH_RESULTS_REFRESH;
      }
    },
    refetchIntervalInBackground: true,
    enabled: !!searchId,
  });

  useErrorToast(errorSearch);

  return { searchResults, isfetchingResults, isLoadingResults, errorSearch };
};

/**
 * Posts a search query to the backend and returns the searchId.
 *
 * @param {string} caseId - The case ID to search profiles for.
 *
 * @returns {Object} An object with three properties: searchProfiles, searchId, searchProfilesStatus.
 * searchProfiles is a function to call to post the search query.
 * searchId is the ID of the search query.
 * searchProfilesStatus is a string representing the status of the search query (either "idle", "loading", "success", or "error").
 */

export const useSearchProfiles = (caseId: string) => {
  const queryClient = useQueryClient();

  const {
    mutate: searchProfiles,
    data: searchId,
    isPaused: searchProfilesPaused,
    status: searchProfilesStatus,
    error,
  } = useMutation({
    mutationFn: async (userInputs: {
      search_entry: string;
      platform: string;
    }) => {
      const searchInputs = {
        case_id: caseId,
        ...userInputs,
      };
      const searchId = await postProfileSearch(searchInputs);
      return searchId;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [casesQueryKey, caseId, profileSearchQueryKey],
      });
    },
  });

  useErrorToast(error);
  useBadNetworkToast(searchProfilesPaused);

  return {
    searchProfiles,
    searchId,
    searchProfilesStatus,
  };
};

/**
 * Downloads a profile artifact based on a search ID
 *
 * @param caseId case ID
 * @param searchId search ID
 * @returns an object with `downloadProfile` mutation function and
 *   `downloadProfileSuccess`, `downloadProfilePending`, and `downloadProfileError`
 *   states
 */
export const useDownloadProfile = (caseId: string, searchId: string) => {
  const queryClient = useQueryClient();

  const {
    mutate: downloadProfile,
    isSuccess: downloadProfileSuccess,
    isPaused: downloadProfilePaused,
    isPending: downloadProfilePending,
    error: downloadProfileError,
  } = useMutation({
    mutationFn: postProfile,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [casesQueryKey, caseId, profileSearchQueryKey, searchId],
      });
    },
  });

  useErrorToast(downloadProfileError);
  useBadNetworkToast(downloadProfilePaused);

  return {
    downloadProfile,
    downloadProfileSuccess,
    downloadProfilePending,
    downloadProfileError,
  };
};
