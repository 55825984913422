import { useNavigate, useParams } from "react-router-dom";

import {
  Table,
  TableHead,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
} from "@/components/ui/table";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

import {
  ArrowDownToLine,
  CircleAlertIcon,
  LoaderCircle,
  OctagonX,
  UserRoundCheck,
} from "lucide-react";

import {
  useDownloadProfile,
  useProfileSearchResults,
} from "@/features/openSearch/hooks";
import { ArtifactStatus } from "@/api/services/artifact";
import { ProfileSearchStatus } from "@/api/services/openSearch";
import { useState } from "react";
import { ModalPopup } from "@/components/ui/modal-popup";
import { useGetImageAsBase64 } from "@/components/hooks/image";

const ProfileDownloadButton = ({
  searchResultId,
  status,
  artifactLink,
}: {
  searchResultId: string;
  status: number | undefined;
  artifactLink: string | undefined;
}) => {
  const navigate = useNavigate();
  const { id: caseId, searchId } = useParams() as {
    id: string;
    searchId: string;
  };
  const { downloadProfile, downloadProfilePending } = useDownloadProfile(
    caseId,
    searchId
  );
  const { searchResults } = useProfileSearchResults(caseId, searchId);

  const handleDownload = (searchResultId: string) => {
    if (!searchResults) {
      return;
    } else if (status === undefined) {
      const searchResult = searchResults.results.find(
        (result) => result.search_result_id === searchResultId
      );

      const platform = searchResults.platform;
      const description = `Profile of ${searchResult?.profile_id}`;

      const profileFields = {
        case_id: caseId,
        search_result_id: searchResultId,
        platform: platform,
        description: description,
      };

      downloadProfile(profileFields);
    }
  };

  if (downloadProfilePending || status === ArtifactStatus.PROCESSING) {
    return (
      <Button variant="outline" size="sm" disabled={true}>
        <LoaderCircle size={16} className="animate-[spin_2s_linear_infinite]" />
      </Button>
    );
  } else if (status === ArtifactStatus.DOWNLOADED) {
    return (
      <Button size="sm" onClick={() => artifactLink && navigate(artifactLink)}>
        <UserRoundCheck size={16} className="mr-1" />
        View
      </Button>
    );
  } else if (status === ArtifactStatus.ARCHIVED) {
    return (
      <Button variant="outline" size="sm" disabled={true}>
        Archived
      </Button>
    );
  } else {
    return (
      <Button
        variant="outline"
        size="sm"
        disabled={status === ArtifactStatus.NONE} // download is disabled when total downloads exceeded maximum count
        onClick={() => handleDownload(searchResultId)}
      >
        <ArrowDownToLine size={16} className="mr-1 text-muted-foreground" />
        Download
      </Button>
    );
  }
};

const ProfilePic = ({
  profileLink,
  name,
}: {
  profileLink: string;
  name: string;
}) => {
  const [expand, setExpand] = useState(false);
  const { base64Image } = useGetImageAsBase64(profileLink);

  return (
    <>
      {expand && (
        <>
          <ModalPopup onClose={() => setExpand(false)}>
            <img src={base64Image} className="w-full mx-auto" alt="" />
          </ModalPopup>
        </>
      )}
      <Avatar
        onClick={() => {
          setExpand(true);
        }}
        className="size-10 sm:size-12"
      >
        <AvatarImage src={base64Image} />
        <AvatarFallback>{name?.charAt(0).toUpperCase()}</AvatarFallback>
      </Avatar>
    </>
  );
};

const InfoAlert = () => {
  return (
    <Alert>
      <CircleAlertIcon className="mr-2" />
      <AlertTitle>Tip!</AlertTitle>
      <AlertDescription>
        Downloaded profiles will be exported into case.
      </AlertDescription>
    </Alert>
  );
};

const WarningAlert = () => {
  return (
    <Alert className="border-sky-100 text-sky-900 bg-sky-50">
      <ArrowDownToLine className="mr-2" color="rgb(12 74 110)" />
      <AlertTitle>Download Limit Reached!</AlertTitle>
      <AlertDescription>
        You have reached the maximum number of downloads allowed for this
        search.
      </AlertDescription>
    </Alert>
  );
};
const ErrorAlert = () => {
  return (
    <Alert>
      <OctagonX className="mr-2" />
      <AlertTitle>Oh no! 😥</AlertTitle>
      <AlertDescription>This search could not be processed.</AlertDescription>
    </Alert>
  );
};

export const SearchResults = () => {
  const { id: caseId, searchId } = useParams() as {
    id: string;
    searchId: string;
  };

  const { searchResults } = useProfileSearchResults(caseId, searchId);

  if (
    searchResults &&
    searchResults.results &&
    (searchResults.status === ProfileSearchStatus.SCRAPED ||
      searchResults.status === ProfileSearchStatus.COMPLETED)
  ) {
    const maxDownloadReached =
      searchResults.download_count >= searchResults.max_download_count;

    return (
      <>
        {maxDownloadReached ? <WarningAlert /> : <InfoAlert />}
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead colSpan={2}>
                <p className="line-clamp-1 break-all">
                  {searchResults.results.length} results for "
                  {searchResults.search_entry}" on {searchResults.platform}
                </p>
              </TableHead>
              <TableHead className="text-right">
                📥 {searchResults.download_count} of{" "}
                {searchResults.max_download_count}
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {searchResults.results &&
              searchResults.results.map((result) => (
                <TableRow key={result.search_result_id}>
                  <TableCell>
                    <ProfilePic
                      profileLink={result.profile_pic}
                      name={result.display_name}
                    />
                  </TableCell>
                  <TableCell>
                    <p className="font-medium mb-1">{result.profile_id}</p>
                    <p className="text-muted-foreground">
                      {result.display_name}
                    </p>
                  </TableCell>
                  <TableCell className="text-right">
                    {searchResults.download_count <
                    searchResults.max_download_count ? (
                      <ProfileDownloadButton
                        searchResultId={result.search_result_id}
                        status={result.artifact_status}
                        artifactLink={
                          result.artifact_id
                            ? `/cases/${caseId}/artifacts/${result.artifact_id}`
                            : undefined
                        }
                      />
                    ) : (
                      <ProfileDownloadButton
                        searchResultId={result.search_result_id}
                        status={ArtifactStatus.NONE}
                        artifactLink={undefined}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </>
    );
  } else if (
    searchResults &&
    searchResults.status === ProfileSearchStatus.FAILED
  ) {
    return <ErrorAlert />;
  } else {
    return (
      <div className="border-b w-full py-4">
        <Skeleton className="w-1/2 md:w-1/4 h-4 mr-auto" />
      </div>
    );
  }
};
