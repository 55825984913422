import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils/tailwind";

const badgeVariants = cva(
  "inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 w-fit",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        success:
          "border-transparent bg-green-200/70 text-green-900 hover:bg-green-200/80",
        progress:
          "border-transparent bg-sky-200/70 text-sky-900 hover:bg-sky-200/80",
        destructive:
          "border-transparent bg-red-200/70 text-red-900 hover:bg-red-200/80",
        archived:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        outline: "text-foreground bg-white",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

const badgeStatusVariants = cva("size-2 rounded-full mr-1.5", {
  variants: {
    variant: {
      default: "",
      success: "bg-green-500",
      progress: "bg-sky-400",
      destructive: "bg-red-400",
      archived: "bg-slate-500",
      secondary: "",
      outline: "",
    },
  },
});

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({
  className,
  variant,
  isStatus = false,
  ...props
}: BadgeProps & { isStatus?: boolean }) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props}>
      {isStatus && <div className={badgeStatusVariants({ variant })}></div>}
      {props.children}
    </div>
  );
}

export { Badge, badgeVariants };
