import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
      clientId: process.env.REACT_APP_CLIENT_ID || "",
      authority: process.env.REACT_APP_AUTH_AUTHORITY || "",
      redirectUri: process.env.REACT_APP_REDIRECT_URI || ""
  },
    system: {	
        loggerOptions: {	
            loggerCallback: (level: LogLevel, message: String, containsPii: boolean) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }	
            }	
        }	
    }
};

export const loginRequest = {
    scopes: ["User.Read"]
};
