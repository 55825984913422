import { CryptoAddress } from "@/api/services/artifact";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils/tailwind";
import { CategoryBadge, getUnqiueCategories } from "./Category";

export const TransactionField = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={cn("flex flex-row items-center space-x-2", className)}>
      {props.children}
    </div>
  );
};

export const TransactionFieldText = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  return <div className={cn(className)}>{props.children}</div>;
};

export const TransactionDirectionBadge = ({
  relevantAddress,
  senderAddresses,
}: {
  relevantAddress: string;
  senderAddresses: CryptoAddress[];
}) => {
  if (
    senderAddresses.filter(
      (address) => address.blockchainAddress === relevantAddress
    ).length > 0
  ) {
    return (
      <Badge className="rounded-sm border-red-700 border" variant="destructive">
        OUT
      </Badge>
    );
  } else {
    return (
      <Badge className="rounded-sm border-green-700 border" variant="success">
        IN
      </Badge>
    );
  }
};

export const TransactionAddresses = ({
  relevantAddress,
  addresses,
  variant,
}: {
  relevantAddress: string;
  addresses: CryptoAddress[];
  variant: "collapsed" | "expanded";
}) => {
  if (variant === "collapsed" && addresses.length === 1) {
    return <span>{addresses[0].blockchainAddress}</span>;
  } else if (variant === "collapsed" && addresses.length > 1) {
    return <span>{addresses.length + " addresses"}</span>;
  } else if (variant === "expanded") {
    return (
      <div className="border-l border-slate-300 pl-6 pb-4 flex flex-col space-y-2">
        {addresses.map((address) => (
          <div className="flex flex-col space-y-2">
            <span
              className={cn(
                "font-mono",
                address.blockchainAddress === relevantAddress
                  ? "font-semibold"
                  : "text-muted-foreground"
              )}
            >
              {address.blockchainAddress}
            </span>
            <div className="flex flex-row gap-x-2 gap-y-2 flex-wrap">
              {getUnqiueCategories(
                address.entities.map((cryptoEntity) => {
                  return {
                    category: cryptoEntity.category,
                    suspiciousLevel: cryptoEntity.suspiciousLevel,
                  };
                })
              ).map((categoryInfo) => (
                <CategoryBadge
                  variant={categoryInfo.suspiciousLevel}
                  state="inactive"
                  className="opacity-50"
                >
                  {categoryInfo.category}
                </CategoryBadge>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return <div />;
  }
};
