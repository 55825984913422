import { HttpErrorStatusCode, ProcessedAxiosError } from "@/api/utils/errors";
import { AuthError } from "@azure/msal-browser";
import { useEffect } from "react";
import { useToast } from "@/components/hooks/use-toast";

export const useErrorToast = (error: Error | null) => {
  const { toast } = useToast();

  const getDisplayMessage = (error: Error) => {
    if (
      error instanceof ProcessedAxiosError &&
      error.response?.status === HttpErrorStatusCode.NotFound // ignore 404 errors
    ) {
      return null;
    } else if (
      error instanceof ProcessedAxiosError &&
      error.isValidationErrorResponse() // ignore validation errors
    ) {
      return null;
    } else if (error instanceof ProcessedAxiosError) {
      return error.displayMessage;
    } else if (error instanceof AuthError) {
      return error.errorMessage;
    } else {
      return "Unknown Error. Please try again later.";
    }
  };

  useEffect(() => {
    if (error) {
      const displayMessage = getDisplayMessage(error);

      if (displayMessage) {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: displayMessage,
        });
      }
    }
  }, [error, toast]);
};

export const useBadNetworkToast = (isPaused: boolean) => {
  const { toast } = useToast();

  useEffect(() => {
    if (isPaused) {
      toast({
        title: "Waiting for connection...",
        description: "Please check your network settings.",
      });
    }
  }, [isPaused, toast]);
};
