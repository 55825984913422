import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useCryptoSearch } from "@/features/cryptoSearch/hooks";
import { useGetCryptoArtifact } from "@/features/artifacts/crypto/hooks";

export const SearchForm = () => {
  const { id: caseId, artifactId } = useParams() as {
    id: string;
    artifactId: string | undefined;
  };
  const navigate = useNavigate();

  const { searchBlockchainAddress, artifactId: newArtifactId } =
    useCryptoSearch(caseId);

  const { artifact } = useGetCryptoArtifact(caseId, artifactId);

  const [searchEntry, setSearchEntry] = useState(
    artifact === undefined ? "" : artifact.metadata.blockchain_address
  );

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (searchEntry) {
      searchBlockchainAddress({
        blockchain_address: searchEntry,
      });
    }
  };

  useEffect(() => {
    if (newArtifactId) {
      navigate(`/cases/${caseId}/crypto-search/${newArtifactId}`);
    }
    // eslint-disable-next-line
  }, [newArtifactId]);

  useEffect(() => {
    if (artifact) {
      setSearchEntry(artifact.metadata.blockchain_address);
    }
  }, [artifact]);

  return (
    <form
      className="flex justify-center w-full py-2 sm:pb-0 flex-wrap space-y-2 lg:space-x-3 lg:space-y-0 lg:flex-nowrap"
      onSubmit={submit}
    >
      <Input
        placeholder="Search for a blockchain address..."
        value={searchEntry}
        onChange={(e) => setSearchEntry(e.target.value)}
      ></Input>
      <Button type="submit" className="w-full lg:w-fit">
        Search
      </Button>
    </form>
  );
};
