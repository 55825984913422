import { backendAxiosInstance } from "@/api/axiosInstance";
import { ENDPOINTS } from "@/api/endpoints";

export enum CaseStatus {
  Open = "Open",
  Archived = "Archived",
}

export type Case = {
  case_id: string;
  report_number: string;
  description: string;
  created_datetime: string;
  last_accessed_datetime: string;
  status: CaseStatus;
};

export type CaseForm = {
  report_number: string;
  description: string;
};

export const getCases = async (): Promise<Case[]> => {
  return backendAxiosInstance
    .get(ENDPOINTS.backendServices.cms)
    .then((response) => response.data);
};

export const postCases = async (caseFields: CaseForm) => {
  return backendAxiosInstance.post(ENDPOINTS.backendServices.cms, caseFields);
};

export const getCasesbyId = async (case_id: string): Promise<Case> => {
  return backendAxiosInstance
    .get(`${ENDPOINTS.backendServices.cms}/${case_id}`)
    .then((response) => response.data);
};
