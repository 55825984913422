import { Button } from "@/components/ui/button";
import { useGetCase } from "@/features/cases/hooks";
import { SearchForm } from "@/features/cryptoSearch/SearchForm";
import { SearchResults } from "@/features/cryptoSearch/SearchResults";
import { SearchPageLogo, SearchPageMain } from "@/layout/SearchLayout";
import { ChevronLeft } from "lucide-react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { GenericLoadingPage } from "@/pages/LoadingPage";
import CryptoSearchLogo from "@/assets/crypto-search-logo.svg";

export const CryptoSearchLandingPage = () => {
  return (
    <div className="container w-full h-3/5 lg:h-1/2 content-end">
      <SearchPageLogo
        logo={<img src={CryptoSearchLogo} alt="CryptoSearch logo" />}
        appTitle="CryptoSearch"
        appCaption="Explore latest transactions on popular blockchains"
      />
      <SearchForm />
    </div>
  );
};

export const CryptoSearchResultsPage = () => {
  return (
    <>
      <SearchForm />
      <div className="overflow-hidden w-full pb-4 lg:pb-12 pt-4">
        <SearchResults />
      </div>
    </>
  );
};

export const CryptoSearchIndex = () => {
  const { id: caseId } = useParams() as { id: string };
  const { caseData } = useGetCase(caseId);
  const navigate = useNavigate();

  if (!caseData) {
    return <GenericLoadingPage />;
  }

  return (
    <SearchPageMain>
      <Button
        variant="back"
        onClick={() => navigate(`/cases/${caseId}`)}
        className="w-full justify-start px-0 sm:px-0"
      >
        <ChevronLeft className="mr-2" />
        {caseData.report_number}
      </Button>
      <Outlet />
    </SearchPageMain>
  );
};
