import axios from "axios";
import { backendAxiosInstance } from "@/api/axiosInstance";
import { ENDPOINTS } from "@/api/endpoints";

// ====================== Artifact Definition ======================
export enum ArtifactStatus {
  NONE = 0, // only used in frontend
  ARCHIVED = 1,
  PROCESSING = 2,
  // SCRAPED = 3, - not used for artifacts
  DOWNLOADED = 4,
  FAILED = 5,
}

export enum ArtifactType {
  PROFILE = 1,
  CRYPTO = 2,
}

export type Artifact = {
  artifact_id: string;
  artifact_type: ArtifactType;
  description: string;
  platform: string;
  created_datetime: string;
  status: ArtifactStatus;
};

export const getArtifacts = async (case_id: string): Promise<Artifact[]> => {
  const url = `${ENDPOINTS.backendServices.cms}/${case_id}/artifacts`;
  return backendAxiosInstance.get(url).then((response) => response.data);
};

// ====================== Profile Artifact Type Definition ======================

export enum ContentType {
  POST = 1,
  STORY = 2,
  REEL = 3,
}

export enum MediaType {
  IMAGE = 4,
  VIDEO = 5,
  LINK = 6,
}

export type ProfileMetadata = {
  profile_id: string;
  display_name: string;
  biography: string;
  profile_pic: string;
};

export type PostContent = {
  owners: string[];
  text: string;
  datetime: string;
  media_content: {
    url?: string;
    original_url: string;
    type: MediaType;
  }[];
};

export type Post = {
  error_message: string;
  source: string;
  type: ContentType;
  reposted_content: PostContent | {};
} & PostContent;

export type ProfileArtifact = {
  platform: string;
  aritfact_type: ArtifactType.PROFILE;
  metadata: ProfileMetadata;
  contents: Post[];
};

// ====================== Crypto Artifact Type Definition ======================

export enum SuspiciousLevel {
  LOW = 0,
  MEDIUM = 1,
  HIGH = 2,
}

export type CryptoEntity = {
  category: string;
  categoryId: string;
  suspiciousLevel: SuspiciousLevel;
};

export type CryptoAddress = {
  blockchainAddress: string;
  chain: string;
  entities: CryptoEntity[];
};

export type CryptoTransaction = {
  transactionHash: string;
  transactionTimestamp: string;
  trmAppUrl: string;
  senderAddresses: CryptoAddress[];
  receiverAddresses: CryptoAddress[];
  relevantAmountRaw: number;
  relevantAmountUsd: number | null;
  assetDisplayName: string;
  suspiciousLevel: SuspiciousLevel;
};

export type CryptoArtifact = {
  platform: string;
  artifact_type: ArtifactType.CRYPTO;
  status: ArtifactStatus;
  metadata: { blockchain_address: string };
  contents: CryptoTransaction[];
};

export const getArtifact = async (
  case_id: string,
  artifact_id: string
): Promise<ProfileArtifact | CryptoArtifact> => {
  const url = `${ENDPOINTS.backendServices.cms}/${case_id}/artifacts/${artifact_id}`;
  return backendAxiosInstance.get(url).then((response) => response.data);
};

export const getMediaImage = async (url: string): Promise<string> => {
  if (url.startsWith("https://osi-api.azure-api.net/")) {
    return backendAxiosInstance
      .get(url, { responseType: "blob" })
      .then((response) => window.URL.createObjectURL(response.data));
  } else {
    return axios
      .get(url, { responseType: "blob" })
      .then((response) => window.URL.createObjectURL(response.data));
  }
};
