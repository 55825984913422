import { Button } from "@/components/ui/button";
import { Check } from "lucide-react";

type CaseFormProps = {
  closeSuccess: () => void;
};

export const CaseFormSuccess = ({ closeSuccess }: CaseFormProps) => {
  return (
    <div className="flex flex-col space-y-2 items-center pt-4">
      <div className="flex items-center justify-center rounded-full bg-primary h-10 w-10">
        <Check className="h-6 w-6 text-primary-foreground" />
      </div>
      <h3
        className="text-lg font-semibold tracking-tight text-gray-900 text-center"
        id="modal-title"
      >
        Success!
      </h3>
      <p className="text-sm text-gray-500 pb-4">
        Case details have been submitted.
      </p>
      <Button variant="outline" className="w-full" onClick={closeSuccess}>
        Ok
      </Button>
    </div>
  );
};
