import { ContentType, ProfileMetadata } from "@/api/services/artifact";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { PostGrid } from "@/features/artifacts/profile/Posts";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Film, Ghost, Newspaper, SquareStack, UserRound } from "lucide-react";
import { useGetProfile } from "@/features/artifacts/profile/hooks";
import { useGetImageAsBase64 } from "@/components/hooks/image";
import { useParams } from "react-router-dom";
import { Skeleton } from "@/components/ui/skeleton";

const sortContentTypes = (profileContentTypes: number[]) => {
  profileContentTypes.sort((a, b) => a - b);
  return profileContentTypes;
};

const getContentTypeLabel = (profileContentType: number) => {
  const ContentTypeLabels: { [key in ContentType]: string } = {
    [ContentType.POST]: "Posts",
    [ContentType.STORY]: "Stories",
    [ContentType.REEL]: "Reels",
  };

  if (profileContentType in ContentType) {
    return ContentTypeLabels[
      profileContentType as keyof typeof ContentTypeLabels
    ];
  }
  return "Others";
};

const iconMap: { [key: number]: React.ReactNode } = {
  [ContentType.POST]: <Newspaper className="hidden sm:inline size-4 mr-2" />,
  [ContentType.STORY]: <SquareStack className="hidden sm:inline size-4 mr-2" />,
  [ContentType.REEL]: <Film className="hidden sm:inline size-4 mr-2" />,
};

const ProfileMetadataCard = ({
  platform,
  profileMetadata,
}: {
  platform: string;
  profileMetadata: ProfileMetadata;
}) => {
  const { base64Image } = useGetImageAsBase64(profileMetadata.profile_pic);

  return (
    <Card className="w-full bg-slate-50">
      <CardHeader>
        <div className="flex space-x-6">
          <Avatar className="border size-16">
            <AvatarImage src={base64Image} />
            <AvatarFallback>
              <UserRound className="text-slate-500" />
            </AvatarFallback>
          </Avatar>
          <div className="text-sm flex flex-col space-y-1 grow">
            <CardTitle className="mb-1">
              {platform} ID: {profileMetadata.profile_id}
            </CardTitle>
            <CardDescription>{profileMetadata.display_name}</CardDescription>
            <CardDescription>{profileMetadata.biography}</CardDescription>
          </div>
        </div>
      </CardHeader>
    </Card>
  );
};

export const ProfileDisplay = () => {
  const { id: caseId, artifactId } = useParams() as {
    id: string;
    artifactId: string;
  };
  const { artifact: profileDetails } = useGetProfile(caseId, artifactId);

  if (!profileDetails) {
    return (
      <>
        <Skeleton className="h-8 w-full" />
        <Skeleton className="h-12 w-full" />
      </>
    );
  }

  const profileContentTypes = sortContentTypes(
    Object.keys(profileDetails["contents"]).map(Number)
  );

  return (
    <div className="flex flex-col space-y-6 md:space-y-8 xl:space-y-12">
      <ProfileMetadataCard
        platform={profileDetails.platform}
        profileMetadata={profileDetails.metadata}
      />
      {profileContentTypes.length > 0 ? (
        <Tabs
          defaultValue={getContentTypeLabel(profileContentTypes[0])}
          className="w-full"
        >
          <TabsList className="w-full">
            {profileContentTypes.map((contentType) => (
              <TabsTrigger value={getContentTypeLabel(contentType)}>
                {iconMap[contentType]}
                {getContentTypeLabel(contentType)}
              </TabsTrigger>
            ))}
          </TabsList>
          {profileContentTypes.map((contentType) => (
            <TabsContent value={getContentTypeLabel(contentType)}>
              <PostGrid
                posts={profileDetails.contents[contentType as ContentType]}
              />
            </TabsContent>
          ))}
        </Tabs>
      ) : (
        <div className="flex justify-center space-x-2">
          <Ghost className="mr-2" />
          Profile has no content.
        </div>
      )}
    </div>
  );
};
