import { getMediaImage } from "@/api/services/artifact";
import { QueryClient, useQuery } from "@tanstack/react-query";

export const prefetchImages = async (
  queryClient: QueryClient,
  urls: string[]
) => {
  urls.forEach((url) => {
    if (queryClient.getQueriesData({ queryKey: ["media", url] }).length === 0) {
      queryClient.prefetchQuery({
        // prefetch if data does not exist
        queryKey: ["media", url],
        queryFn: async () => await getMediaImage(url),
      });
    }
  });
};

export const useGetImageAsBase64 = (imgUrl: string | undefined) => {
  const { data: base64Image } = useQuery({
    queryKey: ["media", imgUrl],
    queryFn: () => "",
    staleTime: Infinity, // prevent refetching if image is already cached
    enabled: !!imgUrl,
  });

  return { base64Image };
};
