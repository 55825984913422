import { cn } from "@/lib/utils/tailwind";
import { ExternalLinkIcon } from "lucide-react";

interface HyperLinkProps extends React.HTMLAttributes<HTMLDivElement> {
  url: string;
}

export const Hyperlink = ({ url, className, ...props }: HyperLinkProps) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className={cn(
        "underline cursor-pointer flex items-center text-sky-500",
        className
      )}
    >
      {props.children}
      <ExternalLinkIcon className="size-3 ml-1" />
    </a>
  );
};
