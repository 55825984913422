import { isValidDate } from "@/lib/utils/datetime";
import { z } from "zod";

const reportNumberFormat = (val: string) => {
  const substrings = val.split("/");
  const regexStr = /^([A-Z]{1}|IP)\/[0-9]{8}\/[0-9]{4}$/;
  if (
    regexStr.test(val.toUpperCase()) &&
    isValidDate(substrings[1], "YYYYMMDD")
  ) {
    return true;
  }

  return false;
};

// do client-side validation with zod
export const caseFormSchema = z.object({
  report_number: z
    .string()
    .min(1, { message: "This field is required." })
    .refine(reportNumberFormat, "Report number has an invalid format."),
  description: z.string().min(1, { message: "This field is required." }),
});
