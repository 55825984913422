import { useRef } from "react";

type ModalProps = {
  onClose: () => void;
  children?: React.ReactNode;
};

const ModalPopup = ({ onClose, children }: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleBackgroundClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose(); // close if mousedown not within modal area
    }
  };

  return (
    <>
      <div
        className="relative z-50"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        onMouseDown={handleBackgroundClick}
      >
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>

        <div className="fixed inset-0 z-50 w-full overflow-y-auto no-scrollbar">
          <div className="flex min-h-full justify-center items-center">
            <div
              className="relative rounded-lg bg-white text-left w-full max-w-lg max-h-3/4 px-6 pb-4 pt-6"
              ref={modalRef}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ModalPopup };
